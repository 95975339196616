import { EditableTable } from "components/EditableTable/EditableTable";
import { TableSkeleton } from "components/Skeletons/TableSkeleton";
import { useAtomValue } from "jotai";
import { columnNames } from "library/constants";
import { genUUID } from "library/utils";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useGetCompanyActionHistory } from "state/queries/useGetCompanyActionHistory";
import { selectedCompanyState } from "store/UIHrPage";
import styled from "styled-components";
import { defaultColumnSizingOptions, getActionHistoryColumns } from "./columns";

export function ActionHistoryView() {
  const { t } = useTranslation();
  const selectedCompany = useAtomValue(selectedCompanyState);

  const { data: companyActions, isLoading: dataLoading } =
    useGetCompanyActionHistory({
      countryCode: selectedCompany?.countryCode || null,
      clientInternalNumberGOS: selectedCompany?.clientInternalNumberGos || "",
      enabled: !!selectedCompany,
    });

  const columns = getActionHistoryColumns({
    t,
  });

  const tableItems = useMemo(() => {
    if (!companyActions) return [];
    return companyActions.map((item) => {
      return {
        ...item,
        id: genUUID(),
      };
    });
  }, [companyActions]);

  return (
    <Container>
      {dataLoading ? (
        <TableSkeleton rowItems={10} />
      ) : (
        <EditableTable
          defaultColumnSizingOptions={defaultColumnSizingOptions}
          defaultItems={tableItems}
          columns={columns}
          columnNames={columnNames}
          selectionMode={"single"}
          withPagination={false}
          onRowClick={(row) => undefined}
        />
      )}
    </Container>
  );
}

const Container = styled.div`
  max-width: 1200px;
  table {
    .fui-TableBody {
      //   .fui-TableRow:nth-of-type(odd) {
      //     background: ${({ theme }) => theme.palette.white};
      //   }
      //   .fui-TableRow:nth-of-type(even) {
      //     background: ${({ theme }) => theme.palette.white};
      //   }
    }
  }
`;
