import { UploadFile } from "components/BenefitCategoryDialog/UploadFile";
import { FilteringCombobox } from "components/controls/FilteringCombobox";
import InputField from "components/controls/InputField";
import { TextareaField } from "components/controls/TextareaField";
import { Grid } from "components/styled";
import { useFormikContext } from "formik";
import { useTaxonomyOptions } from "hooks/useTaxonomyOptions";
import { ETaxonomy } from "library/types";
import { useTranslation } from "react-i18next";

export function ClientFormItems() {
  const { t } = useTranslation();
  const { values, setFieldValue, errors } = useFormikContext<FormData>();

  const sicCodeOptions = useTaxonomyOptions(ETaxonomy.SicCode, true);

  return (
    <>
      <Grid gridColumns={2}>
        <InputField
          label={t("hbh.clientName.label")}
          name="clientName"
          value={values["clientName"]}
          setFieldValue={setFieldValue}
          error={errors["clientName"]}
          disabled
        />
        <UploadFile
          setFieldValue={setFieldValue}
          fieldName="coverPhoto"
          defaultValue={values["coverPhoto"]}
          label={t("hbh.chooseCompanyCoverPhoto.label")}
        />
        <FilteringCombobox
          error={errors["sicCode"]}
          setFieldValue={setFieldValue}
          value={values["sicCode"]}
          label={t("hbh.sicCode.label")}
          options={sicCodeOptions}
          name={"sicCode"}
          required
        />
      </Grid>
      <Grid gridColumns={1}>
        <TextareaField
          label={t("hbh.emailInvitationText.label")}
          name="emailInvitationText"
          value={values["emailInvitationText"]}
          setFieldValue={setFieldValue}
          error={errors["emailInvitationText"]}
          rows={3}
          resize="vertical"

          // required
        />
      </Grid>
    </>
  );
}
