import i18n from "i18next";
import Backend from "i18next-http-backend";
import { APP_ID } from "library/constants";
import { EAppSettings } from "library/types";
import { initReactI18next } from "react-i18next";
import { getUserAppSettings } from "state/queries/useGetUserAppSettings";
import { buildYupLocale } from "validation";
import { urlFormat } from "./library/utils";

const defaultLanguage = "en-GB";

const phraseUrl = urlFormat(process.env.REACT_APP_PHRASE_BASE_URL || "");

const languageDetector = {
  type: "languageDetector" as any, // Exact type is 'backend' | 'logger' | 'languageDetector' | 'postProcessor' | 'i18nFormat' | '3rdParty'
  async: true, // flags below detection to be async
  detect: async (callback: Function) => {
    const userAppSettings = await getUserAppSettings({
      appId: APP_ID,
    });
    const lng = userAppSettings.find(
      (setting) => setting.userAppSettingName === EAppSettings.language
    );

    // const lng = localStorage.getItem(STORAGE_KEYS.appLang);

    // console.log(lng, "lng here");
    // return callback(JSON.parse(lng) || defaultLanguage);
    // const parsedLng = lng ? JSON.parse(lng) : "";
    return callback(lng?.userAppSettingValue || defaultLanguage);
  },
  init: () => {},
  cacheUserLanguage: () => {},
};
i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .use(Backend)
  .use(languageDetector)
  .init(
    {
      keySeparator: false,
      interpolation: {
        escapeValue: false,
      },
      load: "currentOnly",
      backend: {
        // for all available options read the backend's repository readme file
        loadPath: `${phraseUrl}/{{lng}}.json`,
      },
      fallbackLng: (code: string) => {
        if (!code || code === "en-US") return ["en-GB", "de-AT"];

        if (code === "de-DE") return ["de-AT", "en-GB"];
        if (code === "de-CH") return ["de-AT", "en-GB"];
        if (code === "de-AT") return ["de-AT", "en-GB"];

        return ["en-GB", "de-AT"];
      },
      saveMissing: true,

      missingKeyHandler: (lngs, _, key, fallbackValue) => {
        // Check if the key is available in 'en-GB', and if so, return its value

        if (lngs[1] === "en-GB") {
          return i18n.t(key, { lng: "en-GB" });
        }

        // If not found in 'en-GB', return the fallback value
        console.warn(`Missing translation key: ${key}`);
        return fallbackValue;
      },
      supportedLngs: [
        "en-GB",
        "de-AT",
        "bg-BG",
        "cs-CZ",
        "et-EE",
        "hr-HR",
        "hu-HU",
        "lt-LT",
        "pl-PL",
        "ro-RO",
        "ru-RU",
        "sr-Latn-RS",
        "sk-SK",
        "sl-SI",
        "tr-TR",
        "uk-UA",
      ],
      postProcess: ["phraseInContextEditor"],
    },
    buildYupLocale
  );

export default i18n;
(window as any).i18n = i18n;
