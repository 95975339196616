import {
  Button,
  Dialog,
  DialogActions,
  DialogBody,
  DialogContent,
  DialogSurface,
  DialogTitle,
  DialogTrigger,
  makeStyles,
  TableCellLayout,
} from "@fluentui/react-components";
import {
  Dismiss24Regular,
  NotepadPerson24Regular,
} from "@fluentui/react-icons";
import { AppSpinner } from "components/AppSpinner/AppSpinner";
import { PrimaryButton } from "components/PrimaryButton/PrimaryButton";
import { Flex } from "components/styled";
import { Form, Formik } from "formik";
import { useAtomValue } from "jotai";
import { devices } from "library/constants";
import {
  EPaidBy,
  IBenefitPackageView,
  IEmployeeEnrollmentBase,
  IEmployeeView,
} from "library/types";
import {
  formatDateFieldForPayload,
  formatSelectFieldForPayload,
} from "library/utils";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import { useUpdateEmployeeDependentEnrollment } from "state/queries/useUpdateEmployeeDependentEnrollment";
import { useUpdateEmployeeEnrollment } from "state/queries/useUpdateEmployeeEnrollment";
import { companyPackagesState } from "store/UIHrPage";
import styled from "styled-components";
import * as yup from "yup";
import { EnrollmentFormFields } from "./EnrollmentFormFields";
import { useEnrollmentItemDetails } from "./useEnrollmentItemDetails";

type Props = {
  open: boolean;
  setOpen: (state: boolean) => void;
  withSelectedBenefit?: boolean;
  packageData?: IBenefitPackageView;
  packageUser?: IEmployeeView;
  employeeEnrollment?: IEmployeeEnrollmentBase;
};

export function EnrollmentsFormDialog({
  open,
  setOpen,
  packageData,
  packageUser,
  employeeEnrollment,
  withSelectedBenefit,
}: Props) {
  const { t } = useTranslation();

  const companyPackages = useAtomValue(companyPackagesState);

  const { enrollmentItem: formObject, enrollmentIsLoading } =
    useEnrollmentItemDetails({
      employee: packageUser,
      benefitPackage: packageData,
      employeeEnrollment: employeeEnrollment,
    });

  const { mutate: onUpdateEnrollment, isLoading: updateInProgress } =
    useUpdateEmployeeEnrollment();

  const {
    mutate: onUpdateDependentEnrollment,
    isLoading: updateDependentEnrolmentInProgress,
  } = useUpdateEmployeeDependentEnrollment();

  const styles = useStyles();

  const handleSubmit = (values) => {
    if (!packageUser) return;
    if (packageUser.isDependent) {
      onUpdateDependentEnrollment(
        {
          countryCode: formObject?.countryCode,
          clientInternalNumberGOS: formObject?.clientInternalNumberGos,
          benefitPackageTag: formObject?.benefitPackageTag,

          employeeLoginEmailAddress:
            formObject?.employeeLoginEmailAddress || "",
          employeeDependentTag: formObject?.employeeDependentTag,
          payload: {
            ...formObject,
            benefitPackageTag: formObject?.benefitPackageTag,

            employeeLoginEmailAddress:
              formObject?.employeeLoginEmailAddress || "",
            dateOfCancellation: formatDateFieldForPayload(
              values.dateOfCancellation
            ),

            dateOfEligibility: formatDateFieldForPayload(
              values.dateOfEligibility
            ),

            dateOfEnrollment: formatDateFieldForPayload(
              values.dateOfEnrollment
            ),
            endDateOfEligibility: formatDateFieldForPayload(
              values.endDateOfEligibility
            ),

            cancellationPossibility: formatDateFieldForPayload(
              values.cancellationPossibility
            ),
            reasonForCancellation: values?.reasonForCancellation,

            //packagefields
            isAutoEnrollment: values?.isAutoEnrollment ? true : false,
            paymentMethod: formatSelectFieldForPayload({
              values,
              field: "paymentMethod",
            }),
            paymentType: formatSelectFieldForPayload({
              values,
              field: "paymentType",
            }),
            price: Number(values.price),
            usingPercentagesForPaidBy:
              values?.usingPercentagesForPaidBy === EPaidBy.Percentage,
            paidByCompany: Number(values.paidByCompany),
            paidByEmployee: Number(values.paidByEmployee),
          },
        },
        {
          onSuccess: () => {
            toast.success(t("hbh.packageEnrollmentUpdatedSuccessfully.label"));
            setOpen(false);
          },
        }
      );
      return;
    }

    onUpdateEnrollment(
      {
        countryCode: Number(formObject?.countryCode),
        clientInternalNumberGOS: Number(formObject?.clientInternalNumberGos),
        benefitPackageTag: Number(formObject?.benefitPackageTag),
        employeeLoginEmailAddress: formObject?.employeeLoginEmailAddress || "",
        employeeEnrollmentTag: Number(formObject?.employeeEnrollmentTag),
        payload: {
          ...formObject,
          employeeLoginEmailAddress:
            formObject?.employeeLoginEmailAddress || "",
          benefitPackageTag: formObject?.benefitPackageTag,

          dateOfCancellation: formatDateFieldForPayload(
            values.dateOfCancellation
          ),

          dateOfEligibility: formatDateFieldForPayload(
            values.dateOfEligibility
          ),
          dateOfEnrollment: formatDateFieldForPayload(values.dateOfEnrollment),
          endDateOfEligibility: formatDateFieldForPayload(
            values.endDateOfEligibility
          ),
          cancellationPossibility: formatDateFieldForPayload(
            values.cancellationPossibility
          ),
          reasonForCancellation: values?.reasonForCancellation,

          //packagefields
          isAutoEnrollment: values?.isAutoEnrollment ? true : false,
          paymentMethod: formatSelectFieldForPayload({
            values,
            field: "paymentMethod",
          }),
          paymentType: formatSelectFieldForPayload({
            values,
            field: "paymentType",
          }),
          price: Number(values.price),
          usingPercentagesForPaidBy:
            values?.usingPercentagesForPaidBy === EPaidBy.Percentage,
          paidByCompany: Number(values.paidByCompany),
          paidByEmployee: Number(values.paidByEmployee),
        },
      },
      {
        onSuccess: () => {
          toast.success(t("hbh.packageEnrollmentUpdatedSuccessfully.label"));

          setOpen(false);
        },
      }
    );
  };

  const validationSchema = useMemo(() => {
    return yup.object().shape({
      package: yup.mixed().required(),
      dateOfEligibility: yup.mixed().required(),
      endDateOfEligibility: yup.mixed().required(),
      cancellationPossibility: yup.mixed().required(),
      //packagefields
      paymentMethod: yup.mixed().required(),
      paymentType: yup.mixed().required(),
      price: yup.string().required(),
      usingPercentagesForPaidBy: yup.mixed().required(),
      paidByCompany: yup.string().required(),
      paidByEmployee: yup.string().required(),
    });
  }, []);

  const initialValues = useMemo(() => {
    if (!formObject) return {};

    return {
      package: {
        ...(companyPackages || {})[formObject?.benefitPackageTag || ""],
        text: (companyPackages || {})[formObject?.benefitPackageTag || ""]
          ?.name,
        value: formObject?.benefitPackageTag,
        paymentType: formObject?.paymentType,
        paymentMethod: formObject?.paymentMethod,
        price: formObject?.price,
        usingPercentagesForPaidBy: formObject?.usingPercentagesForPaidBy,
        paidByCompany: formObject?.paidByCompany,
        paidByEmployee: formObject?.paidByEmployee,
      },
      dateOfEnrollment: formObject?.dateOfEnrollment,
      dateOfEligibility: formObject?.dateOfEligibility,
      endDateOfEligibility: formObject?.endDateOfEligibility,
      dateOfCancellation: formObject?.dateOfCancellation,
      cancellationPossibility: formObject?.cancellationPossibility,
      reasonForCancellation: formObject?.reasonForCancellation,
      isAutoEnrollment:
        (companyPackages || {})[formObject?.benefitPackageTag || ""]?.name
          ?.isAutoEnrollment || false,
    };
  }, [companyPackages, formObject]);

  const title = `${packageUser?.firstName} ${packageUser?.lastName}: ${t(
    "hbh.enrollOnPackage.label",
    {
      name: packageData?.name,
    }
  )}`;

  if (enrollmentIsLoading) {
    return <AppSpinner />;
  }

  return (
    <Dialog
      modalType="alert"
      open={open}
      onOpenChange={(event, data) => setOpen(data.open)}
    >
      <StyledDialogSurface
        style={{
          bottom: "10%",
        }}
        onClick={(e) => {
          e.stopPropagation();
        }}
      >
        {(updateInProgress || updateDependentEnrolmentInProgress) && (
          <AppSpinner />
        )}
        <Formik
          enableReinitialize
          validateOnChange={false}
          validateOnMount={false}
          validateOnBlur={true}
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={(values, actions) => {
            handleSubmit(values);
            actions.setSubmitting(false);
          }}
        >
          {({
            errors,
            /* and other goodies */
          }) => {
            return (
              <Form>
                <DialogBody className={styles.content}>
                  <DialogTitle
                    action={
                      <DialogTrigger action="close">
                        <Button
                          appearance="subtle"
                          aria-label="close"
                          icon={<Dismiss24Regular />}
                        />
                      </DialogTrigger>
                    }
                  >
                    <Flex width={"100%"} justify="flex-start">
                      <NotepadPerson24Regular />
                      <TableCellLayout truncate>{title}</TableCellLayout>
                    </Flex>
                  </DialogTitle>
                  <DialogContent className={styles.content}>
                    <EnrollmentFormFields
                      packageUser={packageUser}
                      packageData={packageData}
                      withSelectedBenefit={withSelectedBenefit}
                    />
                  </DialogContent>
                  <DialogActions>
                    <DialogTrigger disableButtonEnhancement>
                      <Button appearance="secondary">
                        {t("greco.cancel")}
                      </Button>
                    </DialogTrigger>
                    <PrimaryButton
                      disabled={
                        updateInProgress || updateDependentEnrolmentInProgress
                      }
                      style={{
                        paddingLeft: "25px",
                        paddingRight: "25px",
                      }}
                    >
                      {t("greco.save")}
                    </PrimaryButton>
                  </DialogActions>
                </DialogBody>
              </Form>
            );
          }}
        </Formik>
      </StyledDialogSurface>
    </Dialog>
  );
}

const StyledDialogSurface = styled(DialogSurface)`
  margin-left: 10px;
  margin-right: 10px;
  width: 95%;
  min-width: 95%;
  max-height: 98vh;
  padding: 15px;
  top: 10%;

  @media only screen and ${devices.md} {
    width: 1200px;
    min-width: 1200px;
    padding: 24px;

    margin-left: auto;
    margin-right: auto;
  }
`;

const useStyles = makeStyles({
  body: {
    display: "flex",
    flexDirection: "column",
    width: "1200px",
  },
  content: {
    maxHeight: "90vh",
    scrollbarWidth: "thin",
  },
});
