import {
  EmployeeRowItem,
  IBenefitPackageView,
  IEmployee,
  IEmployeeDependent,
  IEmployeeEnrollmentBase,
  IEmployeeView,
} from "library/types";
import { useGetDependentEnrollment } from "state/queries/useGetDependentEnrollment";
import { useGetEmployeeEnrollment } from "state/queries/useGetEmployeeEnrollment";

type Props = {
  employee?: IEmployeeView;
  benefitPackage?: IBenefitPackageView;
  employeeEnrollment?: IEmployeeEnrollmentBase;
};

export function useEnrollmentItemDetails({
  employee,
  benefitPackage,
  employeeEnrollment,
}: Props) {
  const isDependent = employee?.isDependent;

  const {
    data: employeeEnrollmentItem,
    isLoading: employeeEnrollmentItemLoading,
  } = useGetEmployeeEnrollment({
    countryCode: Number(benefitPackage?.countryCode),
    clientInternalNumberGOS: Number(benefitPackage?.clientInternalNumberGos),
    employeeLoginEmailAddress: employee?.employeeLoginEmailAddress || "",
    benefitPackageTag: Number(benefitPackage?.benefitPackageTag),
    employeeEnrollmentTag: Number(employeeEnrollment?.employeeEnrollmentTag),
    enabled: !isDependent,
  });

  const {
    data: dependentEnrollmentItem,
    isLoading: dependentEnrollmentItemLoading,
  } = useGetDependentEnrollment({
    countryCode: Number(benefitPackage?.countryCode),
    clientInternalNumberGOS: Number(benefitPackage?.clientInternalNumberGos),
    employeeLoginEmailAddress: employee?.employeeLoginEmailAddress || "",
    benefitPackageTag: Number(benefitPackage?.benefitPackageTag),
    employeeDependentTag: Number(employee?.employeeDependentTag),
    employeeEnrollmentTag: Number(employeeEnrollment?.employeeEnrollmentTag),
    enabled: isDependent,
  });

  return {
    enrollmentIsLoading:
      employeeEnrollmentItemLoading || dependentEnrollmentItemLoading,
    enrollmentItem: isDependent
      ? dependentEnrollmentItem
      : employeeEnrollmentItem,
  };
}
// GetEmployeeDependentEnrollment, i BenefitPackageView sada sadrzi CurrencyCode ... swagger je updateovan
