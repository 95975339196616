import {
  Breadcrumb,
  BreadcrumbDivider,
  BreadcrumbItem,
  Label,
} from "@fluentui/react-components";
import { isBefore } from "date-fns";
import {
  IBenefitPackageView,
  IEmployeeEnrollment,
  IEmployeeEnrollmentBase,
  IEmployeeView,
} from "library/types";
import { formatDateString, isAfterOrOnToday } from "library/utils";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { AvatarWithPreview } from "../../../../../../../../components/AvatarWithPreview/AvatarWithPreview";
import { EmployeeEnrollmentsActions } from "../EmployeeEnrollmentsActions/EmployeeEnrollmentsActions";
import { BenefitInfoText } from "./BenefitInfoText";
import { devices } from "library/constants";

type Props = {
  enrolledPackage: IEmployeeEnrollment;
  packageData: IBenefitPackageView;
  packageUser: IEmployeeView;
  enrollment: IEmployeeEnrollmentBase;
};

export function PackageRowItem({
  enrolledPackage,
  packageData,
  packageUser,
  enrollment,
}: Props) {
  const { t } = useTranslation();
  return (
    <Container>
      <Wrap
        packageExpired={isBefore(packageData?.validTo || "", new Date())}
        canceled={!!enrolledPackage?.dateOfCancellation}
        canceledInFuture={isAfterOrOnToday(enrolledPackage?.dateOfCancellation)}
        enrolled={!!enrolledPackage?.dateOfEnrollment}
        isDependent={!!packageUser?.isDependent}
      >
        <Item>
          <AvatarWithPreview
            avatarStyle={{
              borderRadius: "5px",
            }}
            size={28}
            name={`${packageData.name}`}
            imgSrc={packageData.backgroundPicturePath}
          />
        </Item>
        <Item>
          <Breadcrumb aria-label="Breadcrumb default example">
            <StyledBreadcrumbItem>
              {/* <CertificateRegular /> */}
              <BenefitInfoText text={packageData.benefitCategoryName} />
            </StyledBreadcrumbItem>
            <StyledBreadcrumbDivider />
            <StyledBreadcrumbItem>
              {/* <RibbonRegular /> */}
              <BenefitInfoText text={packageData.benefitImplementationName} />
            </StyledBreadcrumbItem>
            <StyledBreadcrumbDivider />
            <StyledBreadcrumbItem>
              <BenefitInfoText text={packageData.name} />
            </StyledBreadcrumbItem>
          </Breadcrumb>
        </Item>
        <Item>
          <Label>{t("hbh.validFromTo.label")}:</Label>
          <Value>
            {formatDateString(packageData?.validFrom || "")} {" - "}
            {formatDateString(packageData?.validTo || "")}
          </Value>
        </Item>
        <Item>
          <Label>{t("hbh.eligibleFrom.label")}:</Label>
          <Value>
            {formatDateString(enrolledPackage?.dateOfEligibility ?? "")}
          </Value>
        </Item>
        {enrolledPackage?.dateOfEnrollment ? (
          <Item>
            <Label>{t("hbh.enrollmentDate.label")}:</Label>
            <Value>{formatDateString(enrolledPackage?.dateOfEnrollment)}</Value>
          </Item>
        ) : null}
        {enrolledPackage?.dateOfCancellation ? (
          <Item>
            <Label>{t("hbh.cancelationDate.label")}:</Label>
            <Value>
              {formatDateString(enrolledPackage?.dateOfCancellation)}
            </Value>
          </Item>
        ) : null}
        <IconWrap>
          <EmployeeEnrollmentsActions
            packageData={packageData}
            packageUser={packageUser}
            enrollment={enrollment}
          />
        </IconWrap>
      </Wrap>
    </Container>
  );
}

export default PackageRowItem;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  box-sizing: border-box;
`;

const Wrap = styled.div<{
  enrolled: boolean;
  canceled: boolean;
  isDependent: boolean;
  packageExpired: boolean;
  canceledInFuture: boolean;
}>`
  display: flex;
  background: ${({
    theme,
    enrolled,
    canceled,
    packageExpired,
    canceledInFuture,
  }) => {
    if (packageExpired) {
      return theme.palette.neutralTertiary;
    }
    if (canceled && !canceledInFuture) {
      return theme.semanticColors.blockingBackground;
    }

    if (canceledInFuture) {
      return `linear-gradient(to right, ${theme.semanticColors.successHighlight} 70%, ${theme.semanticColors.blockingBackground} )`;
    }

    if (enrolled) {
      return theme.semanticColors.successHighlight;
    }

    return theme.palette.neutralLighter;
  }};
  border-left: ${({ theme, canceledInFuture }) => {
    if (canceledInFuture) {
      return `5px solid ${theme.semanticColors.blockingBackground}`;
    }

    return `5px solid transparent`;
  }};
  border-radius: 5px;
  gap: 10px;
  margin: ${({ isDependent }) =>
    isDependent ? "0 10px 2px 50px" : "0 0 2px 0"};
  box-sizing: border-box;
  padding-right: 2px;
`;

const Item = styled.div<{ width?: string }>`
  display: flex;
  gap: 5px;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;

  padding: 5px;
  width: ${({ width }) => (width ? width : "fit-content")};

  @media only screen and ${devices["2xl"]} {
    flex-direction: row;
  }
`;

const Value = styled.div`
  font-weight: 600;
`;

const IconWrap = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  margin-left: auto;
`;

const StyledBreadcrumbItem = styled(BreadcrumbItem)`
  width: 200px;
`;
const StyledBreadcrumbDivider = styled(BreadcrumbDivider)`
  padding: 0 5px;
`;
