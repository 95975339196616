import { atom } from "jotai";
import {
  EAdminTabView,
  ECompanyTabView,
  ECountryTabView,
  EManageHrTabView,
  INotification,
} from "library/types";

export const panelIsOpenState = atom(false);

export const notificationMessagesState = atom<INotification[] | []>([]);

export const selectedHrTabState = atom<EManageHrTabView>(
  EManageHrTabView.summary
);

export const selectedAdminTabState = atom<EAdminTabView>(
  EAdminTabView.benefits
);

export const selectedCountryTabState = atom<ECountryTabView>(
  ECountryTabView.benefits
);

export const selectedCompanyTabState = atom<ECompanyTabView>(
  ECompanyTabView.benefits
);
