import * as React from "react";
import {
  Select,
  InteractionTag,
  InteractionTagPrimary,
  InteractionTagSecondary,
  TagGroup,
  Button,
  makeStyles,
  useId,
  TagGroupProps,
  Overflow,
  OverflowItem,
  Menu,
  MenuItem,
  MenuList,
  MenuPopover,
  MenuTrigger,
  useIsOverflowItemVisible,
  useOverflowMenu,
  shorthands,
  tokens,
  tagClassNames,
} from "@fluentui/react-components";
import { useAtom } from "jotai";
import {
  companyEmployeeViewsAtom,
  visibleEmployeeTagsAtom,
} from "store/UIHrPage";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles({
  root: {
    display: "flex",
    alignItems: "center",
    gap: "10px", // Space between label, select, and tags
  },
  selectGroup: {
    display: "flex",
    alignItems: "center",
    gap: "10px", // Space between Select and Button
    padding: "5px",
  },
  select: {
    width: "200px", // Set the width of the Select component
  },
  tagGroup: {
    display: "flex",
    flexWrap: "wrap", // Allow tags to wrap to the next line if needed
    gap: "5px", // Space between tags
  },
  container: {
    overflow: "hidden",
    padding: "5px",
    zIndex: 0,
    height: "fit-content",
    minWidth: "150px",
    resize: "horizontal",
    width: "100%",
    boxSizing: "border-box",
  },
});

// OverflowMenuItem Component
const OverflowMenuItem = ({
  tag,
}: {
  tag: { children: string; value: string };
}) => {
  const isVisible = useIsOverflowItemVisible(tag.value);
  const styles = makeStyles({
    menuItem: {
      padding: `${tokens.spacingVerticalSNudge} ${tokens.spacingHorizontalXS}`,
      ":hover": {
        [`& .${tagClassNames.root}`]: {
          color: tokens.colorNeutralForeground2Hover,
        },
      },
    },
    tag: {
      backgroundColor: "transparent",
      ...shorthands.borderColor("transparent"),
    },
  })();

  if (isVisible) {
    return null;
  }

  return (
    <MenuItem key={tag.value} className={styles.menuItem}>
      <InteractionTag key={tag.value} value={tag.value} size="small">
        <InteractionTagPrimary hasSecondaryAction>
          {tag.children}
        </InteractionTagPrimary>
        <InteractionTagSecondary aria-label="remove" />
      </InteractionTag>
    </MenuItem>
  );
};

// OverflowMenu Component
const OverflowMenu = ({
  visibleTags,
}: {
  visibleTags: Array<{ children: string; value: string }>;
}) => {
  const { ref, isOverflowing, overflowCount } =
    useOverflowMenu<HTMLButtonElement>();

  if (!isOverflowing) {
    return null;
  }

  return (
    <InteractionTag size="small">
      <Menu>
        <MenuTrigger disableButtonEnhancement>
          <InteractionTagPrimary
            ref={ref}
            aria-label={`${overflowCount} more tags`}
          >
            {`+${overflowCount}`}
          </InteractionTagPrimary>
        </MenuTrigger>
        <MenuPopover>
          <MenuList>
            {visibleTags.map((item) => (
              <OverflowMenuItem key={item.value} tag={item} />
            ))}
          </MenuList>
        </MenuPopover>
      </Menu>
    </InteractionTag>
  );
};

const EmployeeTags = () => {
  const { t } = useTranslation();
  const [originalEmployeeViews] = useAtom(companyEmployeeViewsAtom);
  const [visibleTags, setVisibleTags] = useAtom(visibleEmployeeTagsAtom);

  const options = React.useMemo(() => {
    const allTags = originalEmployeeViews?.flatMap(
      (employee) => employee?.tags
    );
    return Array.from(new Set(allTags))
      .map((tag) => ({
        value: tag,
        children: tag,
      }))
      .filter(
        (tag) =>
          !visibleTags.some((visibleTag) => visibleTag.value === tag.value)
      );
  }, [originalEmployeeViews, visibleTags]);

  const styles = useStyles();
  const comboId = useId();
  const [selected, setSelected] = React.useState<string>("");

  const addTag = () => {
    const selectedOption = options.find((option) => option.value === selected);
    if (selectedOption) {
      setVisibleTags([
        ...visibleTags,
        selectedOption as { children: string; value: string },
      ]);
      setSelected(""); // Reset selected state
    }
  };

  const removeTag: TagGroupProps["onDismiss"] = (_e, { value }) => {
    setVisibleTags(visibleTags.filter((tag) => tag.value !== value));
  };

  return (
    <div className={styles.root}>
      <label htmlFor={comboId}>{t("hbh.tags.label")}:</label>
      <div className={styles.selectGroup}>
        <Select
          size="small"
          id={comboId}
          value={selected}
          onChange={(e) => setSelected(e.target.value)}
          className={styles.select}
          title={t("hbh.tags.selectPlaceholder")}
        >
          <option value={""}></option>
          {options.map((option) => (
            <option key={option.value} value={option.value}>
              {option.children}
            </option>
          ))}
        </Select>
        <Button onClick={addTag} disabled={!selected} size="small">
          {t("hbh.tags.addButton")}
        </Button>
      </div>
      <div className={styles.container}>
        <Overflow minimumVisible={2} padding={60}>
          <TagGroup
            onDismiss={removeTag}
            aria-label={t("hbh.tags.tagManagement")}
            className={styles.tagGroup}
          >
            {visibleTags.map((tag) => (
              <OverflowItem key={tag.value} id={tag.value}>
                <InteractionTag key={tag.value} value={tag.value} size="small">
                  <InteractionTagPrimary hasSecondaryAction>
                    {tag.children}
                  </InteractionTagPrimary>
                  <InteractionTagSecondary aria-label="remove" />
                </InteractionTag>
              </OverflowItem>
            ))}
            <OverflowMenu visibleTags={visibleTags} />
          </TagGroup>
        </Overflow>
      </div>
    </div>
  );
};

export default EmployeeTags;
