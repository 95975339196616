import { Persona } from "@fluentui/react-components";
import { IUserAssignment } from "library/types";
import styled from "styled-components";
import { ManageUserAssignment } from "./ManageUserAssignment";

type Props = {
  data: IUserAssignment[];
};
export function UserAssignmentList({ data }: Props) {
  return (
    <Container>
      {data?.map((user) => {
        return (
          <ItemWrap key={user.userId}>
            <Persona
              name={user.userName}
              secondaryText={user.userEmailAddress}
              style={{
                minWidth: "400px",
                maxWidth: "400px",
              }}
            />
            <ManageUserAssignment user={user} />
          </ItemWrap>
        );
      })}
    </Container>
  );
}

const Container = styled.div`
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  max-height: 65vh;
  overflow-y: auto;
`;
const ItemWrap = styled.div`
  display: flex;
  gap: 10px;
  width: fit-content;
  padding-bottom: 5px;
  border-bottom: 1px solid ${(props) => props.theme.palette.neutralLight};
`;
