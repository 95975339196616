import DatePickerField from "components/controls/DatePickerField";
import { FilteringCombobox } from "components/controls/FilteringCombobox";
import InputField from "components/controls/InputField";
import { TextareaField } from "components/controls/TextareaField";
import { PackageValidationFields } from "components/PackageValidationFields/PackageValidationFields";
import { Grid } from "components/styled";
import { useFormikContext } from "formik";
import { useAddEnrollmentPackageOptions } from "hooks/useAddEnrollmentPackageOptions";
import { IBenefitPackageView, IEmployeeView } from "library/types";
import { useTranslation } from "react-i18next";
import { PackageEditableFields } from "../../../EmployeeActions/PackageEditableFields";

type Props = {
  isAddMode?: boolean;
  benefitPackageTag?: number;
  withSelectedBenefit?: boolean;
  packageData?: IBenefitPackageView;
  packageUser?: IEmployeeView;
};

export function EnrollmentFormFields({
  packageData,
  packageUser,
  isAddMode = false,
  withSelectedBenefit,
}: Props) {
  const { t } = useTranslation();
  const employeeDateOfEmployment = packageUser?.dateOfEmployment;

  const { values, setFieldValue, errors } = useFormikContext<FormData>();

  const { options: packagesOptions } = useAddEnrollmentPackageOptions({
    isAddMode,
    employeeLoginEmailAddress: packageUser?.employeeLoginEmailAddress || "",
    benefitPackageTag: withSelectedBenefit
      ? packageData?.benefitPackageTag
      : undefined,
    eligibilities: packageUser?.enrollments || [],
    enrollments: packageUser?.enrollments || [],
  });

  const packageOptionsAsSelectItem = packagesOptions.map((option) => {
    return {
      ...option,
      text: option.name,
      value: option.benefitPackageTag,
    };
  });

  return (
    <>
      <Grid gridColumns={3}>
        {isAddMode ? (
          <FilteringCombobox
            required
            error={errors["package"]}
            setFieldValue={setFieldValue}
            value={values["package"]}
            label={t("hbh.package.label")}
            options={packageOptionsAsSelectItem}
            name={"package"}
          />
        ) : (
          <InputField
            label={t("hbh.package.label")}
            name=""
            value={packageData?.name || ""}
            disabled
            setFieldValue={() => undefined}
          />
        )}
      </Grid>
      <Grid gridColumns={3}>
        <DatePickerField
          required
          error={errors["dateOfEligibility"]}
          name="dateOfEligibility"
          value={values["dateOfEligibility"] as Date}
          setFieldValue={setFieldValue}
          label={t("hbh.eligibilityDate.label")}
        />
        <DatePickerField
          required
          error={errors["endDateOfEligibility"]}
          name="endDateOfEligibility"
          value={values["endDateOfEligibility"] as Date}
          setFieldValue={setFieldValue}
          label={t("hbh.endEligibilityDate.label")}
        />
        <DatePickerField
          required
          error={errors["cancellationPossibility"]}
          name="cancellationPossibility"
          value={values["cancellationPossibility"] as Date}
          setFieldValue={setFieldValue}
          label={t("hbh.cancellationPossibility.label")}
        />
        <DatePickerField
          error={errors["dateOfEnrollment"]}
          name="dateOfEnrollment"
          value={values["dateOfEnrollment"] as Date}
          setFieldValue={setFieldValue}
          label={t("hbh.enrollmentDate.label")}
        />

        <DatePickerField
          error={errors["dateOfCancellation"]}
          name="dateOfCancellation"
          value={values["dateOfCancellation"] as Date}
          setFieldValue={setFieldValue}
          label={t("hbh.cancelationDate.label")}
        />
      </Grid>
      <Grid gridColumns={1}>
        <TextareaField
          label={t("hbh.cancelationReason.label")}
          name="reasonForCancellation"
          value={values["reasonForCancellation"]}
          setFieldValue={setFieldValue}
          error={errors["reasonForCancellation"]}
          rows={3}
          resize="vertical"
        />
      </Grid>
      <Grid>
        <PackageEditableFields />
      </Grid>
      <PackageValidationFields
        gridColumns={3}
        isDependent={packageUser?.isDependent}
        packageData={
          {
            ...packageData,
            dateOfEmployment: employeeDateOfEmployment,
          } as IBenefitPackageView & {
            dateOfEmployment: string;
          }
        }
      />
    </>
  );
}
