import { MUTATION_KEYS, QUERY_KEYS } from "library/shared";
import { IEmployeeDependent } from "library/types";
import { handleAxiosError } from "library/utils";
import { useMutation, useQueryClient } from "react-query";
import api from "service/api";
import { useInvalidateCompanyEmployeeViews } from "./useGetCompanyEmployeeViews";

type Params = {
  countryCode: number | string;
  clientInternalNumberGOS: number | string;
  employeeDependentTag: number | string;

  rowVersion: string;
  employeeLoginEmailAddress: string;
};

const deleteEmployeeDependent = async ({
  countryCode,
  clientInternalNumberGOS,
  employeeDependentTag,
  employeeLoginEmailAddress,
  rowVersion,
}: Params) => {
  const response = await api.patch(
    `/employee/dependent/delete/${countryCode}/${clientInternalNumberGOS}/${employeeLoginEmailAddress}/${employeeDependentTag}`,
    {
      rowVersion,
    }
  );
  return response.data as IEmployeeDependent;
};

export function useDeleteEmployeeDependent(refetch = true) {
  const queryClient = useQueryClient();
  const invalidateCompanyEmployees = useInvalidateCompanyEmployeeViews();

  return useMutation(deleteEmployeeDependent, {
    mutationKey: MUTATION_KEYS.deleteEmployeeDependent,
    onSuccess: () => {
      if (refetch) {
        invalidateCompanyEmployees();
        queryClient.invalidateQueries({
          queryKey: QUERY_KEYS.employeeDetails,
        });
      }
    },
    onError: (error) => {
      // handle error
      handleAxiosError(error);
    },
  });
}
