import { QUERY_KEYS } from "library/shared";
import { ICompanyInfosInfo } from "library/types";
import { handleAxiosError } from "library/utils";
import { UseQueryResult, useQuery, useQueryClient } from "react-query";
import api from "service/api";

type Params = {
  countryCode: number | string | null;
  clientInternalNumberGOS: number | string;
  onDate: string;
  enabled?: boolean;
};

const getCompanyInfos = async ({
  countryCode,
  clientInternalNumberGOS,
  onDate,
}: Params) => {
  if (countryCode === null || clientInternalNumberGOS === null) return;
  const response = await api.get(
    `/company/${countryCode}/${clientInternalNumberGOS}/${onDate}`
  );

  return response.data;
};

export function useGetCompanyInfos({
  countryCode,
  clientInternalNumberGOS,
  onDate,
  enabled = true,
}: Params): UseQueryResult<ICompanyInfosInfo> {
  return useQuery<ICompanyInfosInfo, Error>(
    [QUERY_KEYS.companyInfos, countryCode, clientInternalNumberGOS],
    () => getCompanyInfos({ countryCode, clientInternalNumberGOS, onDate }),
    {
      staleTime: 0,
      refetchOnMount: true,

      // cacheTime: 0,

      enabled: enabled,
      onError: (error: any) => {
        handleAxiosError(error);
      },
    }
  );
}

export const useInvalidateCompanyInfos = () => {
  const queryClient = useQueryClient();
  return queryClient.invalidateQueries({ queryKey: QUERY_KEYS.companyInfos });
};
