import { PageSkeleton } from "components/Skeletons/PageSkeleton";
import { TableSkeleton } from "components/Skeletons/TableSkeleton";
import { PageContent } from "components/styled";
import { Subheader } from "components/Subheader/Subheader";
import { useAtom, useAtomValue, useSetAtom } from "jotai";
import { EManageHrTabView, ITransformedBenefitPackages } from "library/types";
import { useCallback, useEffect, useMemo } from "react";
import { useGetCompanyBenefitPackages } from "state/queries/useGetCompanyBenefitPackages";
import { useGetCompanyEmployeeViews } from "state/queries/useGetCompanyEmployeeViews";
import { selectedHrTabState } from "store";
import {
  companyEmployeeViewsAtom,
  companyPackagesAtom,
  companyPackagesState,
  expandedBenefitsAtom,
  expandedEmployeesAtom,
  onlyActiveImplementationsState,
  searchEmployeeFilterValueState,
  selectedBenefitsAtom,
  selectedCompanyState,
  selectedEmployeeRow,
  selectedEmployeesAtom,
} from "store/UIHrPage";
import {
  selectedBenefitsAtom as selectedBenefitsDashboardAtom,
  expandedBenefitsAtom as expandedBenefitsDashboardAtom,
} from "store/UIHrPageDashboard";

import { HrPageToolbar } from "./components/HrPageToolbar/HrPageToolbar";
import { ManagePageView } from "./components/ManagePageView/ManagePageView";
import { SummarySkeleton } from "./components/ManagePageView/SummaryView/SummarySkeleton";

// const austriaCountryCode = 834;

export function HrPage() {
  const selectedCompany = useAtomValue(selectedCompanyState);
  const selectedTab = useAtomValue(selectedHrTabState);
  const onlyActiveImplementations = useAtomValue(
    onlyActiveImplementationsState
  );
  const selectedItem = useAtomValue(selectedEmployeeRow);

  const [searchEmployeeFilterValue, setSearchEmployeeFilterValue] = useAtom(
    searchEmployeeFilterValueState
  );

  const [companyEmployees, setCompanyEmployees] = useAtom(
    companyEmployeeViewsAtom
  );

  const setCompanyPackageViews = useSetAtom(companyPackagesAtom);

  const setCompanyPackageObj = useSetAtom(companyPackagesState);

  const { data: companyPackages, isLoading: companyPackagesLoading } =
    useGetCompanyBenefitPackages({
      countryCode: selectedCompany?.countryCode || null,
      clientInternalNumberGOS: selectedCompany?.clientInternalNumberGos || "",
      enabled: !!selectedCompany,
    });

  const transformedCompanyPackages = useMemo(() => {
    if (!companyPackages) return {} as ITransformedBenefitPackages;
    const result = {} as ITransformedBenefitPackages;
    setCompanyPackageViews(companyPackages);
    companyPackages.forEach((item) => {
      const tag = item.benefitPackageTag; // assuming benefitPackageTag exists
      if (!tag) return {};
      // If the benefitPackageTag doesn't already exist in the result object, add it
      if (!result[tag]) {
        result[tag] = {};
      }

      // Push the item into the corresponding tag array
      result[tag] = item;
    });
    return result;
  }, [companyPackages]);

  useEffect(() => {
    if (transformedCompanyPackages) {
      setCompanyPackageObj(transformedCompanyPackages);
    }
  }, [transformedCompanyPackages]);

  const { data: employeesDataView, isLoading: loadingEmployeeData } =
    useGetCompanyEmployeeViews({
      countryCode: selectedCompany?.countryCode || null,
      // clientInternalNumberGOS: 408439,
      clientInternalNumberGOS: selectedCompany?.clientInternalNumberGos || "",
      onlyActive: onlyActiveImplementations,
      enabled: !!selectedCompany,
    });

  const employeesData = useMemo(() => {
    // Group the data by employee email
    if (!employeesDataView) return [];

    const employees = employeesDataView.filter(
      (person) => person.isDependent === false
    );
    const dependents = employeesDataView.filter(
      (person) => person.isDependent === true
    );

    const mappedData = employees.map((employee) => {
      const employeeDependents = dependents.filter(
        (dependent) =>
          dependent.employeeLoginEmailAddress ===
          employee.employeeLoginEmailAddress
      );

      // Add the dependents as a new property to the employee object
      const hasDetails =
        employee.enrollments?.length || employeeDependents?.length;

      return {
        ...employee,
        expanded: hasDetails
          ? employee.employeeLoginEmailAddress ===
            selectedItem?.employeeLoginEmailAddress
          : false,
        dependentList: employeeDependents,
      };
    });

    return mappedData;
  }, [employeesDataView]);

  const handleSearchEmployeeFilterValue = useCallback(
    (value) => {
      setSearchEmployeeFilterValue(value);
    },
    [setSearchEmployeeFilterValue]
  );

  useEffect(() => {
    if (employeesData) {
      setCompanyEmployees(
        employeesData?.filter((item) =>
          `${item.firstName} ${item.lastName}`
            .toLowerCase()
            .includes(searchEmployeeFilterValue)
        )
      );
    }
  }, [employeesData, searchEmployeeFilterValue, selectedTab]);

  const setSelectedItems = useSetAtom(selectedEmployeesAtom);
  const setExpandedItems = useSetAtom(expandedEmployeesAtom);
  const setSelectedPackages = useSetAtom(selectedBenefitsAtom);
  const setExpandedPackages = useSetAtom(expandedBenefitsAtom);
  const setSelectedPackagesDashboard = useSetAtom(
    selectedBenefitsDashboardAtom
  );
  const setExpandedPackagesDashboard = useSetAtom(
    expandedBenefitsDashboardAtom
  );

  useEffect(() => {
    setSearchEmployeeFilterValue("");
    setSelectedItems(new Set<string>());
    setExpandedItems(new Set<string>());
    setSelectedPackages(new Set<string>());
    setExpandedPackages(new Set<string>());
    setSelectedPackagesDashboard(new Set<string>());
    setExpandedPackagesDashboard(new Set<string>());
  }, [selectedCompany]);

  const Skeleton = (() => {
    if (selectedTab === EManageHrTabView.summary) {
      return <SummarySkeleton />;
    }
    if (selectedTab === EManageHrTabView.employees) {
      return <PageSkeleton isListView />;
    }
    if (selectedTab === EManageHrTabView.actionHistory) {
      return <TableSkeleton rowItems={10} />;
    }
    if (selectedTab === EManageHrTabView.benefits) {
      return <PageSkeleton isCardView />;
    }
  })();

  if (loadingEmployeeData || companyPackagesLoading) return Skeleton;

  return (
    <>
      <Subheader
        searchFilterValue={searchEmployeeFilterValue}
        isHrPage
        showSearchFilter={selectedTab === EManageHrTabView.employees}
        items={companyEmployees || []}
        setSearchFilterValue={handleSearchEmployeeFilterValue}
      />
      {selectedCompany && <HrPageToolbar />}
      <PageContent>
        {selectedCompany && (
          <>
            <ManagePageView />
          </>
        )}
      </PageContent>
    </>
  );
}
