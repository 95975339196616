import { QUERY_KEYS } from "library/shared";
import { ITaxonomy } from "library/types";
import { handleAxiosError } from "library/utils";
import { UseQueryResult, useQuery } from "react-query";
import api from "service/api";

const getTaxonomies = async () => {
  const response = await api.get(`/taxonomy`);

  return response.data;
};

export function useGetTaxonomies(enabled = true): UseQueryResult<ITaxonomy> {
  return useQuery<ITaxonomy, Error>([QUERY_KEYS.taxonomies], getTaxonomies, {
    enabled: enabled,
    onError: (error: any) => {
      handleAxiosError(error);
    },
  });
}
