import {
  Breadcrumb,
  BreadcrumbButton,
  BreadcrumbItem,
  Link,
} from "@fluentui/react-components";
import styled from "styled-components";

import { BuildingRegular, PeopleSettingsRegular } from "@fluentui/react-icons";
import { RealEstateIcon } from "@fluentui/react-icons-mdl2";
import { AdminALogo32Icon } from "@fluentui/react-icons-mdl2-branded";
import { Flex } from "components/styled";
import { devices, PAGES_PER_ROLES, ROUTES } from "library/constants";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { logout } from "service/GraphService";
import { useGetAuthorization } from "state/queries/useGetAuthorization";
import Logo from "../../assets/GrECo_Logo_small.jpg";

export function UnauthorizedPage() {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const { data: authorization } = useGetAuthorization();

  const pagesUserCanSee = (() => {
    const pages: string[] = [];
    authorization?.roles?.forEach((role) => {
      PAGES_PER_ROLES[role]?.pages.forEach((page) => {
        if (!pages.includes(page)) {
          pages.push(page);
        }
      });
    });
    return pages;
  })();

  return (
    <>
      <Subheader>
        <Breadcrumb aria-label="Breadcrumb default example">
          {pagesUserCanSee.map((page) => {
            if (page === ROUTES.UNAUTHORIZED_PAGE || page === ROUTES.HOME)
              return null;

            const Icon = (() => {
              if (page === ROUTES.ADMIN) return <AdminALogo32Icon />;
              if (page === ROUTES.COUNTRY) return <RealEstateIcon />;
              if (page === ROUTES.COMPANY_PROFILE) return <BuildingRegular />;
              if (page === ROUTES.HR) return <PeopleSettingsRegular />;
              return <></>;
            })();
            return (
              <BreadcrumbItem key={page}>
                <BreadcrumbButton
                  icon={Icon}
                  onClick={() => {
                    navigate(page);
                  }}
                >
                  {page.replace(/-|\//, " ").toUpperCase()}
                </BreadcrumbButton>
              </BreadcrumbItem>
            );
          })}
        </Breadcrumb>
      </Subheader>
      <Container>
        <img
          className="logo"
          src={Logo}
          alt={"GrECo logo"}
          style={{
            height: "44px",
          }}
        />
        <MessageWrap>
          <MessageHeader>{t("hbh.restrictedAccess.label")}</MessageHeader>
          <MessageBody>{t("hbh.restrictedAccessMessage.label")}</MessageBody>
        </MessageWrap>

        <Flex
          direction="column"
          gap={"5px"}
          align="flex-start"
          style={{
            marginTop: "20px",
          }}
        >
          <Link
            id="logout"
            onClick={() => {
              navigate("/");
              logout();
            }}
          >
            {t("header.SignOut")}
          </Link>
        </Flex>
      </Container>
    </>
  );
}

const Container = styled.div`
  height: 90vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  position: relative;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
`;

const MessageWrap = styled.div``;

const MessageHeader = styled.h1`
  margin: 40px 0 20px;
`;
const MessageBody = styled.div``;

const Subheader = styled.div<{ $showSearchFilter?: boolean }>`
  display: flex;
  width: 100%;
  padding: 10px;
  margin: auto;
  position: sticky;
  z-index: 99;
  top: 0px;
  height: ${(props) => (props.$showSearchFilter ? "auto" : "52px")};
  background-color: ${(props) => props.theme.palette.white};
  box-sizing: border-box;
  justify-content: space-between;
  gap: 5px;
  @media only screen and ${devices.md} {
    padding: 10px 30px;
  }
`;
