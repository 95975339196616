import InputField from "components/controls/InputField";
import { RadioGroupField } from "components/controls/RadioGroupField";
import SelectField from "components/controls/SelectField";
import { useFormikContext } from "formik";
import useGetAllCurrencyOptions from "hooks/useGetAllCurrencyOptions";
import useGetCurrencyOptionsPerUser from "hooks/useGetCurrencyOptionsPerUser";
import { useTaxonomyOptions } from "hooks/useTaxonomyOptions";
import { paidByOptions } from "library/constants";
import { EPaidBy, ETaxonomy } from "library/types";
import { useCallback, useEffect } from "react";
import { useTranslation } from "react-i18next";

export function PackageEditableFields(props) {
  const { t } = useTranslation();
  const { values, setFieldValue, errors, setFieldError } =
    useFormikContext<FormData>();
  const paymentMethodOptions = useTaxonomyOptions(ETaxonomy.PaymentMethod);
  const paymentTypeOptions = useTaxonomyOptions(ETaxonomy.PaymentType);

  const currencyOptions = useGetAllCurrencyOptions();

  const currency =
    currencyOptions.find(
      (currency) => currency.value === values["package"]?.currencyCode
    )?.text || "";

  const isPercentageValue =
    values["usingPercentagesForPaidBy"] === EPaidBy.Percentage;

  const price = values["price"];
  const paidByCompany = values["paidByCompany"];
  const paidByEmployee = values["paidByEmployee"];
  const numberTypeLabel = isPercentageValue ? "%" : currency;

  const handleErrors = useCallback(
    async (price, paidByCompany, paidByEmployee) => {
      if (price) {
        if (isPercentageValue) {
          if (Number(paidByEmployee) + Number(paidByCompany) === 100) {
            setFieldError("paidByCompany", "");
            setFieldError("paidByEmployee", "");
          } else {
            setFieldError(
              "paidByCompany",
              t("hbh.paymentsPackagePriceEqualMsg.label")
            );
            setFieldError(
              "paidByEmployee",
              t("hbh.paymentsPackagePriceEqualMsg.label")
            );
          }
          return;
        } else {
          if (
            Number(price) - Number(paidByCompany) - Number(paidByEmployee) !==
            0
          ) {
            setFieldError(
              "paidByCompany",
              t("hbh.paymentsPackagePriceEqualMsg.label")
            );
            setFieldError(
              "paidByEmployee",
              t("hbh.paymentsPackagePriceEqualMsg.label")
            );
          } else {
            setFieldError("paidByCompany", "");
            setFieldError("paidByEmployee", "");
          }
        }
      }
    },
    [isPercentageValue, setFieldError]
  );

  useEffect(() => {
    if (price) {
      handleErrors(price, paidByCompany, paidByEmployee);
    }
  }, [handleErrors, paidByCompany, paidByEmployee, price, setFieldError]);

  useEffect(() => {
    const paymentMethod =
      paymentMethodOptions.find((item) => {
        return item.value === values["package"]?.paymentMethod;
      }) || null;
    const paymentType =
      paymentTypeOptions.find((item) => {
        return item.value === values["package"]?.paymentType;
      }) || null;

    const usingPercentagesForPaidBy = values["package"]
      ? values["package"]?.usingPercentagesForPaidBy
        ? EPaidBy.Percentage
        : EPaidBy.Amount
      : null;

    setFieldValue("paymentMethod", paymentMethod);
    setFieldValue("paymentType", paymentType);
    setFieldValue("price", values["package"]?.price);
    setFieldValue("usingPercentagesForPaidBy", usingPercentagesForPaidBy);
    setFieldValue("paidByCompany", values["package"]?.paidByCompany);
    setFieldValue("paidByEmployee", values["package"]?.paidByEmployee);
  }, [values["package"]]);

  return (
    <>
      <SelectField
        required
        error={errors["paymentMethod"]}
        showEmptyOption
        setFieldValue={setFieldValue}
        value={values["paymentMethod"]}
        label={t("hbh.paymentMethod.label")}
        options={paymentMethodOptions}
        name={"paymentMethod"}
      />
      <SelectField
        required
        error={errors["paymentType"]}
        showEmptyOption
        setFieldValue={setFieldValue}
        value={values["paymentType"]}
        label={t("hbh.paymentType.label")}
        options={paymentTypeOptions}
        name={"paymentType"}
      />
      <InputField
        required
        label={t("hbh.premiumCost.label")}
        name="price"
        type="number"
        value={values["price"]}
        setFieldValue={setFieldValue}
        error={errors["price"] || ""}
      />
      <RadioGroupField
        required
        error={errors["usingPercentagesForPaidBy"]}
        name="usingPercentagesForPaidBy"
        value={values["usingPercentagesForPaidBy"]}
        setFieldValue={setFieldValue}
        label={t("hbh.valueType.label")}
        options={paidByOptions}
        // required
      />
      <InputField
        required
        error={errors["paidByCompany"] || ""}
        setFieldValue={(name, paidByCompany) => {
          setFieldValue(name, paidByCompany);

          if (isPercentageValue) {
            const paidByEmployeeValue =
              Number(paidByCompany) < 101 ? 100 - Number(paidByCompany) : "0";
            const paidByCompanyValue =
              Number(paidByCompany) < 101 ? paidByCompany : 100;

            setFieldValue("paidByEmployee", paidByEmployeeValue || "0");
            setFieldValue("paidByCompany", paidByCompanyValue || "0");
          } else {
            const paidByEmployeeValue =
              Number(paidByCompany) < Number(price)
                ? Number(price) - Number(paidByCompany)
                : "0";
            const paidByCompanyValue =
              Number(paidByCompany) < Number(price)
                ? paidByCompany
                : Number(price);
            setFieldValue("paidByEmployee", paidByEmployeeValue || "0");
            setFieldValue("paidByCompany", paidByCompanyValue || "0");
          }
        }}
        value={values["paidByCompany"] as any}
        label={`${t("hbh.paidByCompany.label")} (${numberTypeLabel})`}
        name={"paidByCompany"}
        type="number"
      />
      <InputField
        required
        error={errors["paidByEmployee"] || ""}
        setFieldValue={(name, paidByEmployee) => {
          setFieldValue(name, paidByEmployee);
          if (isPercentageValue) {
            const paidByCompanyValue =
              Number(paidByEmployee) < 101 ? 100 - Number(paidByEmployee) : "0";
            const paidByEmployeeValue =
              Number(paidByEmployee) < 101 ? paidByEmployee : 100;

            setFieldValue("paidByCompany", paidByCompanyValue || "0");
            setFieldValue("paidByEmployee", paidByEmployeeValue || "0");
          } else {
            const paidByCompanyValue =
              Number(paidByEmployee) < Number(price)
                ? Number(price) - Number(paidByEmployee)
                : "0";
            const paidByEmployeeValue =
              Number(paidByEmployee) < Number(price)
                ? paidByEmployee
                : Number(price);

            setFieldValue("paidByCompany", paidByCompanyValue);
            setFieldValue("paidByEmployee", paidByEmployeeValue);
          }
        }}
        value={values["paidByEmployee"] as any}
        label={`${t("hbh.paidByEmployee.label")} (${numberTypeLabel})`}
        type="number"
        name={"paidByEmployee"}
      />
    </>
  );
}
