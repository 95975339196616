import { Select, SelectProps, useId } from "@fluentui/react-components";
import FormFieldLabel from "components/FormFieldLabel/FormFieldLabel";
import {
  ErrorMessageContainer,
  FormFieldContainer,
  StyledErrorMessage,
} from "components/styled";
import { useAppTheme } from "hooks/useAppTheme";
import { SelectItem } from "library/types";

type Props = {
  name: string;
  label?: string;

  required?: boolean;
  options: SelectItem[];
  value: SelectItem | null;
  setFieldValue: (name: string, value: SelectItem) => void;
  error?: string;
  info?: string;
  noErrorSection?: boolean;
  noLabel?: boolean;
  showEmptyOption?: boolean;
  placeholder?: string;
} & SelectProps;

const SelectField = ({
  name,
  options,
  label = "",
  info,
  required = false,
  value,
  error,
  setFieldValue,
  noErrorSection = false,
  noLabel = false,
  showEmptyOption = false,
  placeholder,
  ...props
}: Props) => {
  const selectId = useId(name);
  const theme = useAppTheme();

  const onChange = (event, data) => {
    const selectedOption = options.find(
      (option) => option.value.toString() === data.value
    ) as SelectItem;

    setFieldValue(name, selectedOption);
  };

  return (
    <FormFieldContainer>
      {!noLabel && (
        <FormFieldLabel label={label} required={required} info={info} />
      )}
      <Select
        appearance="underline"
        id={selectId}
        name={name}
        onChange={onChange}
        value={value?.value || ""}
        {...props}
      >
        {showEmptyOption && (
          <option
            key={"none"}
            value={""}
            style={{
              background: theme.palette.white,
              color: theme.palette.themePrimary,
            }}
          >
            {placeholder}
          </option>
        )}

        {options.map((option: SelectItem) => {
          return (
            <option
              key={option.value}
              disabled={false}
              value={option.value}
              style={{
                background: theme.palette.white,
              }}
            >
              {option?.Icon ?? ""}
              {/* <Text
                style={{
                  color: theme.palette.black,
                }}
              > */}
              {option.text}
              {/* </Text> */}
            </option>
          );
        })}
      </Select>
      {!noErrorSection && (
        <ErrorMessageContainer>
          <StyledErrorMessage>{error}</StyledErrorMessage>
        </ErrorMessageContainer>
      )}
    </FormFieldContainer>
  );
};

export default SelectField;
