import {
  Button,
  Dialog,
  DialogActions,
  DialogBody,
  DialogContent,
  DialogSurface,
  DialogTitle,
  DialogTrigger,
  makeStyles,
  TableCellLayout,
} from "@fluentui/react-components";
import {
  Dismiss24Regular,
  NotepadPerson24Regular,
} from "@fluentui/react-icons";
import { AppSpinner } from "components/AppSpinner/AppSpinner";
import { PrimaryButton } from "components/PrimaryButton/PrimaryButton";
import { Flex } from "components/styled";
import { useAtomValue } from "jotai";
import { devices } from "library/constants";
import { toast } from "react-toastify";
import styled from "styled-components";
import * as yup from "yup";

import { Formik } from "formik";
import { useCreateEmployeeEnrollments } from "state/queries/useCreateEmployeeEnrollments";
import { selectedCompanyState } from "store/UIHrPage";
import EmployeeEnrollmentFormFields from "./EmployeeCancelEnrollmentFormFields";
import { useEffect, useState } from "react";
import { formatDateFieldForPayload } from "library/utils";
import EmployeeCancelEnrollmentFormFields from "./EmployeeCancelEnrollmentFormFields";
import { useTranslation } from "react-i18next";
import { useUpdateEmployeeEnrollments } from "state/queries/useUpdateEmployeeEnrollments";

type Props = {
  open: boolean;
  setOpen: (state: boolean) => void;
  employeeItems?: any[];
  enrollmentItems?: any[];
  benefitItems?: any[];
};

export function EmployeeCancelEnrollmentFormDialog({
  open,
  setOpen,
  employeeItems,
  enrollmentItems,
  benefitItems,
}: Props) {
  const selectedCompany = useAtomValue(selectedCompanyState);
  const [operationResult, setOperationResult] = useState<any>(null);
  const [showOperationResult, setShowOperationResult] = useState(false);

  const { mutate: onUpdateEmployeeEnrollments, isLoading: updatesInProgress } =
    useUpdateEmployeeEnrollments();

  const styles = useStyles();

  useEffect(() => {
    if (open) {
      setShowOperationResult(false);
      setOperationResult(null);
    }
  }, [open]);

  const { t } = useTranslation();

  const handleSubmit = (values) => {
    if (!benefitItems) return;
    let payloadItems: any[] = [];
    enrollmentItems?.forEach((enrollment) => {
      payloadItems.push({
        ...enrollment,
        dateOfCancellation:
          formatDateFieldForPayload(values["dateOfCancellation"]) ?? null,
        reasonForCancellation: values["reasonForCancellation"],
      });
    });

    onUpdateEmployeeEnrollments(
      {
        countryCode: Number(selectedCompany?.countryCode),
        clientInternalNumberGOS: Number(
          selectedCompany?.clientInternalNumberGos
        ),
        enrollments: payloadItems,
      },
      {
        onSuccess: (data: any) => {
          setOperationResult(data);
          toast.success(t("hbh.enrollments.delete.success"));
        },
      }
    );
    return;
  };

  const title = operationResult
    ? t("hbh.dialog.operationSuccess.label")
    : (enrollmentItems ?? []).length === 0
    ? t("hbh.dialog.noEmployees.label")
    : t("hbh.dialog.cancelEnrollments.label", {
        employeeCount: enrollmentItems?.length,
        packageCount: benefitItems?.length,
      });

  const submitLabel = t("hbh.dialog.applyButton.label");

  const validationSchema = yup.object().shape({
    dateOfCancellation: yup.mixed().required(),
    reasonForCancellation: yup.string().required(),
  });

  const initialValues = {
    dateOfCancellation: null,
    reasonForCancellation: null,
  };

  if (!benefitItems) return null;
  return (
    <Formik
      enableReinitialize
      validateOnChange={false}
      validateOnMount={false}
      validateOnBlur={true}
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={(values, actions) => {
        handleSubmit(values);
        actions.setSubmitting(false);
      }}
    >
      {({ values, errors, setFieldValue, submitForm }) => {
        return (
          <Dialog
            modalType="alert"
            open={open}
            onOpenChange={(event, data) => setOpen(data.open)}
          >
            <StyledDialogSurface
              onClick={(e) => {
                e.stopPropagation();
              }}
            >
              {updatesInProgress && <AppSpinner />}

              <DialogBody className={styles.content}>
                <DialogTitle
                  action={
                    <DialogTrigger action="close">
                      <Button
                        appearance="subtle"
                        aria-label={t("hbh.dialog.closeButton.label")}
                        icon={<Dismiss24Regular />}
                      />
                    </DialogTrigger>
                  }
                >
                  <Flex width={"100%"} justify="flex-start">
                    <NotepadPerson24Regular />
                    <TableCellLayout truncate>{title}</TableCellLayout>
                  </Flex>
                </DialogTitle>
                <DialogContent className={styles.content}>
                  {showOperationResult ? (
                    <code
                      dangerouslySetInnerHTML={{
                        __html: operationResult,
                      }}
                    ></code>
                  ) : operationResult ? null : (
                    <EmployeeCancelEnrollmentFormFields />
                  )}
                </DialogContent>
                <DialogActions>
                  <DialogTrigger disableButtonEnhancement>
                    <Button appearance="secondary">
                      {t("hbh.dialog.closeButton.label")}
                    </Button>
                  </DialogTrigger>
                  {!showOperationResult && (
                    <PrimaryButton
                      disabled={
                        updatesInProgress ||
                        (!operationResult &&
                          (enrollmentItems ?? []).length === 0)
                      }
                      style={{
                        paddingLeft: "25px",
                        paddingRight: "25px",
                      }}
                      onClick={() => {
                        operationResult
                          ? setShowOperationResult(true)
                          : submitForm();
                      }}
                    >
                      {operationResult
                        ? t("hbh.dialog.showResults.label")
                        : submitLabel}
                    </PrimaryButton>
                  )}
                </DialogActions>
              </DialogBody>
            </StyledDialogSurface>
          </Dialog>
        );
      }}
    </Formik>
  );
}

const StyledDialogSurface = styled(DialogSurface)`
  margin-left: 10px;
  margin-right: 10px;
  width: 95%;
  min-width: 95%;
  max-height: 98vh;
  padding: 15px;

  @media only screen and ${devices.md} {
    width: 550px;
    min-width: 550px;
    padding: 24px;

    margin-left: auto;
    margin-right: auto;
  }
`;

const useStyles = makeStyles({
  body: {
    display: "flex",
    flexDirection: "column",
    width: "550px",
  },
  content: {
    maxHeight: "90vh",
    scrollbarWidth: "thin",
  },
});
