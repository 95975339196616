import InputField from "components/controls/InputField";
import SelectField from "components/controls/SelectField";
import { useFormikContext } from "formik";
import { ROUTES } from "library/constants";
import { ECheckedValue, SelectItem } from "library/types";
import { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import { useGetBenefitCategories } from "state/queries/useGetBenefitCategories";

type Props = {
  isAddCategory?: boolean;
  countryCode?: number | string;
  clientInternalNumberGOS?: number | string;
  rootCountryCode?: boolean;
};

export function BenefitCategoryNameField({
  isAddCategory,
  countryCode = 0,
  clientInternalNumberGOS = 0,
  rootCountryCode = true,
}: Props) {
  const { t } = useTranslation();
  const location = useLocation();
  const routesWithSelectCategory = [
    ROUTES.COUNTRY,
    ROUTES.COMPANY_PROFILE,
  ].includes(location.pathname);

  const hasCategorySelect = isAddCategory && routesWithSelectCategory;

  const { setValues, values, setFieldValue, errors } =
    useFormikContext<FormData>();

  const [selectedCategory, setSelectedCategory] = useState<SelectItem | null>(
    null
  );

  const { data: rootBenefitCategories } = useGetBenefitCategories({
    countryCode: rootCountryCode ? 0 : countryCode,
    clientInternalNumberGOS: 0,
    enabled: hasCategorySelect,
  });

  const { data: countryCategories } = useGetBenefitCategories({
    countryCode,
    clientInternalNumberGOS,
    enabled: hasCategorySelect,
  });

  const categoryOptions = useMemo(() => {
    return (rootBenefitCategories?.benefitCategories || [])
      ?.filter((cat) => {
        return !countryCategories?.benefitCategories?.some(
          (countryCat) =>
            countryCat.benefitCategoryParentTag === cat.benefitCategoryTag ||
            countryCat.benefitCategoryTag === cat.benefitCategoryTag
        );
      })
      ?.map((category) => ({
        value: category?.benefitCategoryTag || "",
        text: category?.name || "",
        ...category,
      }));
  }, [rootBenefitCategories, countryCategories]);

  if (!hasCategorySelect) {
    return (
      <InputField
        disabled={countryCode !== 0} //on admin page we can't change the name
        label={t("hbh.name.label")}
        name="name"
        value={values["name"]}
        setFieldValue={setFieldValue}
        error={errors["name"]}
        required
      />
    );
  }

  return (
    <>
      <SelectField
        required
        showEmptyOption
        error={""}
        setFieldValue={(name, benefitCategory) => {
          //   const benefitCategory = benefitCategories?.find((category) => {
          //     return category.benefitCategoryTag === selectedCategory?.value;
          //   });
          //   const consentValues = consentOptions.filter((option) => {
          //     return benefitCategory?.consentTypes?.includes(option.value);
          //   });

          //   console.log(consentValues, "consentValues consentValues");

          setSelectedCategory(benefitCategory);
          setValues({
            benefitCategory: benefitCategory?.value,

            name: benefitCategory?.name || "",
            consentTypes: benefitCategory?.consentTypes || null,
            uploadDocuments: benefitCategory?.uploadDocuments
              ? ECheckedValue.Yes
              : ECheckedValue.No,
            consentsNeeded: benefitCategory?.consentsNeeded
              ? ECheckedValue.Yes
              : ECheckedValue.No,
            descriptionAsDocument: benefitCategory?.descriptionAsDocument
              ? ECheckedValue.Yes
              : ECheckedValue.No,
            enrollmentProcessNeeded: benefitCategory?.enrollmentProcessNeeded
              ? ECheckedValue.Yes
              : ECheckedValue.No,
            coverPhoto: benefitCategory?.backgroundPicturePath
              ? {
                  name: benefitCategory?.backgroundPictureName,
                  path: benefitCategory?.backgroundPicturePath,
                  base64String: benefitCategory?.backgroundPictureContent,
                  type: benefitCategory?.backgroundPictureMimeType,
                }
              : null,
            description: benefitCategory?.description || "",
            benefitCategoryParentTag: benefitCategory?.benefitCategoryTag,
          } as any);
        }}
        value={selectedCategory as any}
        label={t("hbh.benefitCategory.label")}
        options={categoryOptions}
        name={"benefitCategory"}
      />
    </>
  );
}
