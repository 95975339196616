import { useSelectedViewTab } from "hooks/useSelectedViewTab";
import { EBenefitTab } from "library/types";
import { CardListSkeleton } from "./CardListSkeleton";
import { TableSkeleton } from "./TableSkeleton";

type Props = {
  isListView?: boolean;
  isCardView?: boolean;
};

export function BenefitSkeleton({ isListView, isCardView }: Props) {
  const selectedView = useSelectedViewTab();

  if (isListView) {
    return <TableSkeleton />;
  }
  if (isCardView) {
    return <CardListSkeleton />;
  }
  if (selectedView === EBenefitTab.list) {
    return <TableSkeleton />;
  }

  return <CardListSkeleton />;
}
