import { useAtomValue } from "jotai";
import { selectedBenefitsAtom } from "store/UIHrPage";
import { useTranslation } from "react-i18next";

const BenefitsSelectedInfo = () => {
  const { t } = useTranslation();
  const selectedPackages = useAtomValue(selectedBenefitsAtom);

  return (
    <div style={{ marginTop: "40px" }}>
      {t("hbh.benefits.selectedInfo.label", {
        count: Array.from(selectedPackages).length,
      })}
    </div>
  );
};

export default BenefitsSelectedInfo;
