import { MUTATION_KEYS } from "library/shared";
import { IUserSettings } from "library/types";
import { handleAxiosError } from "library/utils";
import { useMutation } from "react-query";
import api from "service/api";
import { useInvalidateUserAppSettings } from "./useGetUserAppSettings";

type Params = {
  payload: IUserSettings;
};

const updateAppSettings = async ({ payload }: Params) => {
  const response = await api.patch(`/uad/updateappsettings`, payload);

  return response.data as IUserSettings[];
};

export function useUpdateAppSettings(refetch = true) {
  const invalidateUserAppSettings = useInvalidateUserAppSettings();

  return useMutation(updateAppSettings, {
    mutationKey: MUTATION_KEYS.updateUserSettings,
    onSuccess: () => {
      if (refetch) {
        invalidateUserAppSettings();
      }
    },
    onError: (error) => {
      // handle error
      handleAxiosError(error);
    },
  });
}
