import { EditableTable } from "components/EditableTable/EditableTable";
import { useAtom, useAtomValue } from "jotai";
import { columnNames } from "library/constants";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { companyEmployeeViewsAtom, selectedEmployeeRow } from "store/UIHrPage";
import styled from "styled-components";
import { SubrowComponent } from "./SubrowComponent/SubrowComponent/SubrowComponent";
import {
  defaultColumnSizingOptions,
  getEnrolledEmployeesTableColumns,
} from "./columns";

export function EmployeesTable() {
  const { t } = useTranslation();
  const companyEmployees = useAtomValue(companyEmployeeViewsAtom);

  const [selectedItem, setSelectedItem] = useAtom(selectedEmployeeRow);
  const tableItems = useMemo(() => {
    if (!companyEmployees) return [];
    return companyEmployees
      ?.map((item) => {
        return {
          ...item,
          id: item.employeeLoginEmailAddress,
          // expanded: true,
          showSubrow: item.enrollments?.length || item.dependentList?.length,
        };
      })
      .sort((a, b) => (a?.firstName || "").localeCompare(b?.firstName || ""));
  }, [companyEmployees]);

  const columns = getEnrolledEmployeesTableColumns({
    t,
  });

  return (
    <div>
      <TableWrap>
        <EditableTable
          withPagination={false}
          SubrowComponent={SubrowComponent}
          defaultColumnSizingOptions={defaultColumnSizingOptions}
          defaultItems={tableItems}
          columns={columns}
          columnNames={columnNames}
          onRowClick={(row) => {
            if (selectedItem?.id !== row?.id) {
              row.updateState({
                ...row,
                expanded: true,
              });
              setSelectedItem(row);
            }
          }}
          selectionMode={"single"}
        />
      </TableWrap>
    </div>
  );
}

const TableWrap = styled.div`
  margin-top: 10px;
  table {
    .selected-row:not(.sub-component-row) {
      background-color: ${({ theme }) => theme.palette.themeLighter} !important;
    }
    .sub-component-row {
      background-color: ${({ theme }) => theme.palette.white} !important;
    }

    .with-subrow {
      border: 3px solid ${(props) => props.theme.palette.themeLighter};
      border-bottom: none;
      background-color: ${({ theme }) => theme.palette.white} !important;
    }

    // .selected-row {
    //   background-color: ${({ theme }) =>
      theme.palette.themeLighter} !important;
    // }
  }
`;
