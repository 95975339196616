import {
  Button,
  Dialog,
  DialogActions,
  DialogBody,
  DialogContent,
  DialogSurface,
  DialogTitle,
  DialogTrigger,
  Label,
  makeStyles,
} from "@fluentui/react-components";
import { Dismiss24Regular } from "@fluentui/react-icons";
import { PrimaryButton } from "components/PrimaryButton/PrimaryButton";
import { Flex } from "components/styled";
import { Form, Formik } from "formik";
import { useAtomValue } from "jotai";
import { devices } from "library/constants";
import { useMemo, useState } from "react";
import styled from "styled-components";
import * as yup from "yup";
import { AppSpinner } from "components/AppSpinner/AppSpinner";
import { useAddEmployeeTags } from "state/queries/useAddEmployeeTags";
import { useRemoveEmployeeTags } from "state/queries/useRemoveEmployeeTags";
import { toast } from "react-toastify";
import { selectedCompanyState } from "store/UIHrPage";
import EmployeeTagsField from "./EmployeeTagsField";
import InputField from "components/controls/InputField";
import { useTranslation } from "react-i18next";

type Props = {
  open: boolean;
  setOpen: (open: boolean) => void;
  employeeEmails: string[];
  onSuccess?: () => void;
  operation: "add" | "remove";
};

const useStyles = makeStyles({
  body: {
    display: "flex",
    flexDirection: "column",
    width: "860px",
  },
  content: {
    maxHeight: "90vh",
    scrollbarWidth: "thin",
  },
  root: {
    display: "flex",
    alignItems: "center",
    gap: "10px", // Space between label, select, and tags
  },
  selectGroup: {
    display: "flex",
    alignItems: "center",
    gap: "10px", // Space between Select and Button
    padding: "5px",
  },
  select: {
    width: "200px", // Set the width of the Select component
  },
  tagGroup: {
    display: "flex",
    flexWrap: "wrap", // Allow tags to wrap to the next line if needed
    gap: "5px", // Space between tags
  },
  container: {
    overflow: "hidden",
    padding: "5px",
    zIndex: 0, // stop the browser resize handle from piercing the overflow menu
    height: "fit-content",
    minWidth: "150px",
    resize: "horizontal",
    width: "100%",
    boxSizing: "border-box",
  },
});

export function EmployeeTagsDialog({
  open,
  setOpen,
  employeeEmails,
  onSuccess,
  operation,
}: Props) {
  const { t } = useTranslation();
  const selectedCompany = useAtomValue(selectedCompanyState);

  const { mutate: onAddEmployeeTags, isLoading: addEmployeeTagsInProgress } =
    useAddEmployeeTags();

  const {
    mutate: onRemoveEmployeeTags,
    isLoading: removeEmployeeTagsInProgress,
  } = useRemoveEmployeeTags();

  const styles = useStyles();
  const [tags, setTags] = useState([]);

  const [newTag, setNewTag] = useState("");

  const handleSubmit = (values) => {
    const payload = {
      countryCode: selectedCompany?.countryCode || 0,
      clientInternalNumberGOS: selectedCompany?.clientInternalNumberGos || 0,
      payload: {
        employees: employeeEmails,
        tags: values.tags.map((tag) => tag.value.trim()),
      },
    };

    if (operation === "add") {
      onAddEmployeeTags(payload, {
        onSuccess() {
          toast.success(t("hbh.employeeTags.addSuccess"));
          onSuccess && onSuccess();
          setOpen(false);
        },
      });
    } else {
      onRemoveEmployeeTags(payload, {
        onSuccess() {
          toast.success(t("hbh.employeeTags.removeSuccess"));
          onSuccess && onSuccess();
          setOpen(false);
        },
      });
    }
  };

  const initialValues = useMemo(() => {
    return {
      employees: employeeEmails,
      tags: tags,
    };
  }, [employeeEmails, tags]);

  const validationSchema = yup.object().shape({});

  return (
    <Dialog
      modalType="alert"
      open={open}
      onOpenChange={(event, data) => {
        if (!data.open) {
          onSuccess && onSuccess();
        }
        setOpen(data.open);
      }}
    >
      <StyledDialogSurface
        onClick={(e) => {
          e.stopPropagation();
        }}
      >
        {(addEmployeeTagsInProgress || removeEmployeeTagsInProgress) && (
          <AppSpinner />
        )}
        <Formik
          enableReinitialize
          validateOnChange={false}
          validateOnBlur={false}
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={(values, actions) => {
            handleSubmit(values);
            actions.setSubmitting(false);
          }}
        >
          {({
            values,
            errors,
            setFieldValue,
            submitForm,
            isSubmitting,
            /* and other goodies */
          }) => (
            <Form>
              <DialogBody className={styles.content}>
                <DialogTitle
                  action={
                    <DialogTrigger action="close">
                      <Button
                        appearance="subtle"
                        aria-label={t("hbh.dialog.closeButton.label")}
                        icon={<Dismiss24Regular />}
                      />
                    </DialogTrigger>
                  }
                >
                  <Flex width={"100%"} justify="flex-start">
                    <span>
                      {t(`hbh.employeeTags.${operation}Title`, {
                        operation,
                        employeeCount: employeeEmails.length,
                      })}
                    </span>
                  </Flex>
                </DialogTitle>
                <DialogContent className={styles.content}>
                  <EmployeeTagsField
                    value={values["tags"]}
                    setValue={(value) => setFieldValue("tags", value)}
                  />
                  {operation === "add" && (
                    <div className={styles.root}>
                      <div className={styles.selectGroup}>
                        <Label>{t("hbh.employeeTags.newTag.label")}</Label>
                        <InputField
                          label=""
                          name={"newTag"}
                          value={newTag}
                          setFieldValue={(name, value) => setNewTag(value)}
                          error={errors["newTag"]}
                        />
                        <Button
                          onClick={(e) => {
                            e.stopPropagation();
                            setFieldValue("tags", [
                              ...values["tags"],
                              { value: newTag, children: newTag },
                            ]);
                            setNewTag("");
                          }}
                          disabled={newTag === ""}
                          size="small"
                        >
                          {t("hbh.employeeTags.addNewTagButton.label")}
                        </Button>
                      </div>
                    </div>
                  )}
                </DialogContent>
                <DialogActions>
                  <PrimaryButton
                    style={{
                      paddingLeft: "25px",
                      paddingRight: "25px",
                    }}
                    disabled={isSubmitting || values.tags.length === 0}
                  >
                    {t(`hbh.employeeTags.submit.${operation}`)}
                  </PrimaryButton>
                  <DialogTrigger disableButtonEnhancement>
                    <Button appearance="secondary">
                      {t("hbh.dialog.closeButton.label")}
                    </Button>
                  </DialogTrigger>
                </DialogActions>
              </DialogBody>
            </Form>
          )}
        </Formik>
      </StyledDialogSurface>
    </Dialog>
  );
}

export default EmployeeTagsDialog;

const StyledDialogSurface = styled(DialogSurface)`
  margin-left: 10px;
  margin-right: 10px;
  width: 95%;
  min-width: 95%;
  max-height: 98vh;
  padding: 15px;

  @media only screen and ${devices.md} {
    width: 860px;
    min-width: 860px;
    padding: 24px;

    margin-left: auto;
    margin-right: auto;
  }
`;
