import { Button } from "@fluentui/react-components";
import { PersonSquareCheckmark24Regular } from "@fluentui/react-icons";
import { Flex, StyledTooltip } from "components/styled";
import { useAppTheme } from "hooks/useAppTheme";
import { EmployeeRowItem, IEmployeeView } from "library/types";
import { useCallback, useState } from "react";
import { EligibilityFormDialog } from "./EligibilityFormDialog/EligibilityFormDialog";

type Props = {
  employeeData: EmployeeRowItem;
  dependentData?: IEmployeeView;
};

export function EmployeeEligibility({ employeeData, dependentData }: Props) {
  const { palette } = useAppTheme();

  const disabled = !!employeeData.disabled;

  const [addEligibilityOpen, setAddEligibilityOpen] = useState(false);

  const handleDialogStateChange = useCallback(() => {
    setAddEligibilityOpen((prev) => !prev);
  }, []);

  return (
    <>
      <Flex width={"100%"} justify="center">
        <StyledTooltip
          relationship="label"
          content={{
            children: "Manage Package Eligibility",
          }}
        >
          <Button
            icon={
              <PersonSquareCheckmark24Regular
                style={{
                  color: palette.themePrimary,
                  transform: "scale(1.1)",
                }}
              />
            }
            disabled={disabled}
            appearance="transparent"
            size="small"
            onClick={() => {
              setAddEligibilityOpen(true);
            }}
          />
        </StyledTooltip>
      </Flex>
      {addEligibilityOpen && (
        <EligibilityFormDialog
          packageUser={
            dependentData
              ? {
                  ...dependentData,
                  dateOfEmployment: employeeData?.dateOfEmployment,
                }
              : employeeData
          }
          employeeEnrollmentTag={employeeData?.employeeEnrollmentTag}
          open={addEligibilityOpen}
          setOpen={handleDialogStateChange}
        />
      )}
    </>
  );
}
