import { ETaxonomy } from "library/types";
import { useTaxonomyOptions } from "./useTaxonomyOptions";

function useGetAllCurrencyOptions() {
  const currencyOptions = useTaxonomyOptions(ETaxonomy.Currency);

  return currencyOptions;
}

export default useGetAllCurrencyOptions;
