import { Dropdown, Option } from "@fluentui/react-components";
import useGetCurrencyOptionsPerUser from "hooks/useGetCurrencyOptionsPerUser";
import { useAtom } from "jotai";
import { filterCurrencyAtom } from "store/UIHrPageDashboard";
import { useTranslation } from "react-i18next";
import { ICompanyInfosInfo } from "library/types";
import { useMemo } from "react";
import useGetAllCurrencyOptions from "hooks/useGetAllCurrencyOptions";

interface Props {
  companyInfo?: ICompanyInfosInfo;
}

const FilterCurrency = ({ companyInfo }: Props) => {
  const currencyOptions = useGetCurrencyOptionsPerUser();
  const allCurrencyOptions = useGetAllCurrencyOptions();
  const [filterCurrency, setFilterCurrency] = useAtom(filterCurrencyAtom);
  const { t } = useTranslation();

  const handleOptionSelect = (ev, data) => {
    setFilterCurrency(
      allCurrencyOptions.find((e) => e.value === data.optionValue)
    );
  };

  const availableCurrencies = useMemo(() => {
    let retVal = currencyOptions;
    companyInfo?.benefitImplementations?.forEach((benefitImplementation) => {
      const existingCurrency = currencyOptions.find(
        (currency) => currency.value === benefitImplementation.currencyCode
      );
      if (!existingCurrency) {
        const realExistingCurreny = allCurrencyOptions.find(
          (currency) => currency.value === benefitImplementation.currencyCode
        );
        retVal.push(realExistingCurreny);
      }
    });
    return retVal;
  }, [currencyOptions, companyInfo, allCurrencyOptions]);

  return (
    <Dropdown
      placeholder={t("hbh.filterCurrency.placeholder")}
      title={t("hbh.filterCurrency.title")}
      style={{ marginTop: "10px" }}
      value={String(filterCurrency?.text) || ""}
      onOptionSelect={handleOptionSelect}
    >
      {availableCurrencies.map((option) => (
        <Option key={option.code} value={option.value}>
          {option.text}
        </Option>
      ))}
    </Dropdown>
  );
};

export default FilterCurrency;
