import { MUTATION_KEYS } from "library/shared";
import { IUserSettings, IUserSettingsPayload } from "library/types";
import { handleAxiosError } from "library/utils";
import { useMutation } from "react-query";
import api from "service/api";
import { useInvalidateUserAppSettings } from "./useGetUserAppSettings";

type Params = {
  payload: IUserSettingsPayload[];
};

const addAppSetting = async ({ payload }: Params) => {
  const response = await api.post(`/uad/addappsettings`, payload);

  return response.data as IUserSettings[];
};

export function useAddAppSetting(refetch = true) {
  const invalidateUserAppSettings = useInvalidateUserAppSettings();

  return useMutation(addAppSetting, {
    mutationKey: MUTATION_KEYS.addUserSettings,
    onSuccess: () => {
      if (refetch) {
        invalidateUserAppSettings();
      }
    },
    onError: (error) => {
      // handle error
      handleAxiosError(error);
    },
  });
}
