import { Card } from "@fluentui/react-components";
import CheckboxField from "components/controls/CheckboxField";
import DatePickerField from "components/controls/DatePickerField";
import { Grid } from "components/styled";
import { useFormikContext } from "formik";
import { useAtom } from "jotai";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { filteredCompanyPackagesAtom } from "store/UIHrPage";

const EmployeeEligibilityFormFields = () => {
  const { values, errors, setFieldValue } = useFormikContext<any>();
  const { t } = useTranslation();
  const [companyPackages] = useAtom(filteredCompanyPackagesAtom);

  useEffect(() => {
    if (values.isAutoEligibility) {
      // If toggleField is set to false, clear dependent fields
      setFieldValue("dateOfEligibility", null);
      setFieldValue("endDateOfEligibility", null);
      setFieldValue("cancellationPossibility", null);
    }
  }, [values.isAutoEligibility]);

  return (
    <Card style={{ marginTop: "20px", marginBottom: "20px" }}>
      <Grid gridColumns={3}>
        <DatePickerField
          required={!values["isAutoEligibility"]}
          disabled={values["isAutoEligibility"]}
          error={errors["dateOfEligibility"] as string}
          name={"dateOfEligibility"}
          value={values["dateOfEligibility"] as Date}
          setFieldValue={setFieldValue}
          label={t("hbh.dateOfEligibility.label")}
        />
        <DatePickerField
          required={!values["isAutoEligibility"]}
          disabled={values["isAutoEligibility"]}
          error={errors["endDateOfEligibility"] as string}
          name={"endDateOfEligibility"}
          value={values["endDateOfEligibility"] as Date}
          setFieldValue={setFieldValue}
          label={t("hbh.endDateOfEligibility.label")}
        />
        <DatePickerField
          required={!values["isAutoEligibility"]}
          disabled={values["isAutoEligibility"]}
          error={errors["cancellationPossibility"] as string}
          name={"cancellationPossibility"}
          value={values["cancellationPossibility"] as Date}
          setFieldValue={setFieldValue}
          label={t("hbh.cancellationPossibility.label")}
        />
        <CheckboxField
          key={"isAutoEligibility"}
          name={"isAutoEligibility"}
          value={values["isAutoEligibility"]}
          setFieldValue={setFieldValue}
          label={t("hbh.isAutoEligibility.label")}
        />
        <CheckboxField
          key={"isAutoEnrollment"}
          name={"isAutoEnrollment"}
          value={values["isAutoEnrollment"]}
          setFieldValue={setFieldValue}
          label={t("hbh.isAutoEnrollment.label")}
        />
      </Grid>
    </Card>
  );
};

export default EmployeeEligibilityFormFields;
