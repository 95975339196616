import { QUERY_KEYS } from "library/shared";
import { IUserAssignment } from "library/types";
import { handleAxiosError } from "library/utils";
import { UseQueryResult, useQuery, useQueryClient } from "react-query";
import api from "service/api";

type Params = {
  countryCode: number | string | null;
  clientInternalNumberGOS: number | string;
  enabled?: boolean;
};

const getUserAssignments = async ({
  countryCode,
  clientInternalNumberGOS,
}: Params) => {
  if (countryCode === null || clientInternalNumberGOS === null) return;
  const response = await api.get(
    `/hbhuserassignment/${countryCode}/${clientInternalNumberGOS}`
  );

  return response.data;
};

export function useGetUserAssignments({
  countryCode,
  clientInternalNumberGOS,
  enabled = true,
}: Params): UseQueryResult<IUserAssignment[]> {
  return useQuery<IUserAssignment[], Error>(
    [QUERY_KEYS.userAssignments, countryCode, clientInternalNumberGOS],
    () => getUserAssignments({ countryCode, clientInternalNumberGOS }),
    {
      staleTime: 0,
      refetchOnMount: true,

      // cacheTime: 0,

      enabled: enabled,
      onError: (error: any) => {
        handleAxiosError(error);
      },
    }
  );
}

export const useInvalidateGetUserAssignments = () => {
  const queryClient = useQueryClient();

  return () =>
    queryClient.invalidateQueries({ queryKey: QUERY_KEYS.userAssignments });
};
