import { useAtomValue } from "jotai";
import { EMAIL_ID_SEPARATOR } from "library/utils";
import { selectedEmployeesAtom } from "store/UIHrPage";
import { useTranslation } from "react-i18next";

const EmployeeSelectedInfo = () => {
  const { t } = useTranslation();
  const selectedItems = useAtomValue(selectedEmployeesAtom);

  const employeeCount = Array.from(selectedItems).filter(
    (el) => el.indexOf(EMAIL_ID_SEPARATOR) === -1
  ).length;

  const dependentCount = Array.from(selectedItems).filter(
    (el) => el.indexOf(EMAIL_ID_SEPARATOR) !== -1
  ).length;

  return (
    <div style={{ marginTop: "40px" }}>
      {t("hbh.selectedInfo.summary", {
        employeeCount,
        dependentCount,
      })}
    </div>
  );
};

export default EmployeeSelectedInfo;
