import React from "react";
// -- Fabric UI

// -- Components
import { Drawer } from "@fluentui/react-components";
import { INotification, IUser, SelectItem } from "library/types";
import styled from "styled-components";
import { IGrecoPanelStrings } from "./IGrecoPanelStrings";
import { INotificationStrings } from "./Notification/INotificationStrings";
import Notification from "./Notification/Notification";
import { PanelType as GrecoPanelType } from "./PanelType";
import { ISettingsStrings } from "./Settings/ISettingsStrings";
import { Settings } from "./Settings/Settings";
import { IUserSettingsStrings } from "./UserSettings/IUserSettingsStrings";
import { UserSettings } from "./UserSettings/UserSettings";

import {
  Button,
  DrawerBody,
  DrawerHeader,
  DrawerHeaderTitle,
  useRestoreFocusSource,
  useRestoreFocusTarget,
} from "@fluentui/react-components";
import { Dismiss24Regular } from "@fluentui/react-icons";

interface IProps {
  panelIsOpen: any;
  onDismiss: () => void;
  panelType: GrecoPanelType | null;
  userData: IUser | null;
  logout: () => void;
  darkMode?: any;
  headerConfig: any;
  panelStrings?: IGrecoPanelStrings;
  languageOptions?: SelectItem[];
  defaultSelectedLanguage?: string;
  onChangeLanguage?: (
    event: React.FormEvent<HTMLDivElement>,
    option?: SelectItem,
    index?: number
  ) => void;
  isMobile?: boolean;
  togglePanel?: any;
  notifications?: INotification[];
  clearNotifications?: () => void;
}

export const GrecoPanel = (props: IProps) => {
  const panelStyle = {
    height: "calc(100vh - 50px)",
    top: "50px",
  };

  const renderComponent = () => {
    if (props.panelType === GrecoPanelType.Notifications)
      return (
        <Notification
          strings={props.panelStrings as INotificationStrings}
          notifications={props.notifications}
          clearNotifications={props.clearNotifications}
        />
      );
    else if (props.panelType === GrecoPanelType.UserSettings)
      return (
        <UserSettings
          userData={props.userData}
          logout={props.logout}
          strings={props.panelStrings as IUserSettingsStrings}
          panelType={props.panelType}
        />
      );
    else if (props.panelType === GrecoPanelType.Settings)
      return (
        <Settings
          darkMode={props.darkMode}
          strings={props.panelStrings as ISettingsStrings}
          languageOptions={props.languageOptions}
          defaultSelectedLanguage={props.defaultSelectedLanguage}
          onChangeLanguage={props.onChangeLanguage as any}
        />
      );
    else return <div></div>;
  };

  const restoreFocusTargetAttributes = useRestoreFocusTarget();
  const restoreFocusSourceAttributes = useRestoreFocusSource();

  return (
    <Container>
      <Drawer
        position="end"
        {...restoreFocusSourceAttributes}
        {...restoreFocusTargetAttributes}
        type={"overlay"}
        separator
        size="small"
        open={props.panelIsOpen}
        onOpenChange={(_, { open }) => props.onDismiss()}
        style={props.panelType !== GrecoPanelType.AppLauncher ? panelStyle : {}}
      >
        <DrawerHeader>
          <DrawerHeaderTitle
            action={
              <Button
                style={{
                  display:
                    props.panelType === GrecoPanelType.AppLauncher
                      ? "none"
                      : "block",
                }}
                appearance="subtle"
                aria-label="Close"
                icon={<Dismiss24Regular />}
                onClick={() => props.onDismiss()}
              />
            }
          >
            {(props.panelStrings && props.panelStrings.panelTitle) ||
              props.panelType}
          </DrawerHeaderTitle>
        </DrawerHeader>

        <DrawerBody
          style={{
            padding: 10,
          }}
        >
          {renderComponent()}
        </DrawerBody>
      </Drawer>
    </Container>
  );
};

const Container = styled.div``;
