import { AddCircleRegular, ArrowExportRegular } from "@fluentui/react-icons";
import { AddAttachments } from "components/AddAttachments/AddAttachments";
import { ActionButton, Flex, Grid } from "components/styled";
import { FieldArray, useFormikContext } from "formik";
import { EmployeeRowItem } from "library/types";
import { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { EmployeeDependentsDialog } from "../EmployeeDependentsDialog/EmployeeDependentsDialog";
import { BasicFields } from "../FormFields/BasicFields";
import { MainFields } from "../FormFields/MainFields";
import { EmployeeDependentsFormItems } from "./EmployeeDependentsFormItems";
import { ManageEmployeeAttachments } from "./ManageEmployeeAttachments";

type Props = {
  employeeDetails?: EmployeeRowItem;
  isAddEmployee?: boolean;
};

export function EmployeeFormFields({ employeeDetails, isAddEmployee }: Props) {
  const { t } = useTranslation();
  const { values } = useFormikContext<FormData>();

  const [manageDependentsOpen, setManageDependentsOpen] = useState(false);

  const onManageDependentsOpen = useCallback((state) => {
    setManageDependentsOpen(state);
  }, []);

  const employeeDependentsNumber = employeeDetails?.dependents?.length;
  return (
    <>
      <Container>
        <MainFields isAddMode={isAddEmployee} />
        <BasicFields />

        {isAddEmployee ? (
          <>
            <AddAttachments />

            <Flex
              direction="column"
              gap={"5px"}
              justify="flex-start"
              align="flex-start"
            >
              <DependentCoverageTitle>
                {t("hbh.dependentCoverages.label")}
              </DependentCoverageTitle>

              <FieldArray
                name="dependents"
                validateOnChange={true}
                render={(arrayHelpers) => (
                  <>
                    {values["dependents"]?.map((item, index) => (
                      <EmployeeDependentsFormItems
                        key={index}
                        item={item}
                        index={index}
                        arrayHelpers={arrayHelpers}
                      />
                    ))}
                    <ActionButton
                      noBorder
                      appearance="transparent"
                      icon={<AddCircleRegular />}
                      onClick={() => {
                        //   const id = genUUID();A

                        arrayHelpers.push({
                          dependentType: "",
                          firstName: "",
                          lastName: "",
                          emailAddress: "",
                          dateOfBirth: "",
                          dependentCoverPhoto: null,
                          mobilePhoneNumber: "",
                          phoneNumber: "",
                          genderCodeId: null,
                          personIdentificationNumber: "",
                          socialSecurityNumber: "",
                          addressNameLine1: "",
                          leadingTitle: "",
                          trailingTitle: "",
                          middleName: "",
                          birthplace: "",
                          citizenshipCodeId: null,
                          passportNumber: "",
                        });
                      }}
                    >
                      {t("hbh.addDependent.label")}
                    </ActionButton>
                  </>
                )}
              />
            </Flex>
          </>
        ) : (
          <Grid>
            <ManageEmployeeAttachments
              item={employeeDetails ?? ({} as EmployeeRowItem)}
            />

            <ActionButton
              icon={<ArrowExportRegular />}
              noBorder
              appearance="transparent"
              onClick={(e) => {
                setManageDependentsOpen(true);
              }}
            >
              {t("hbh.manageDependents.label")}
              {employeeDependentsNumber
                ? `(${employeeDependentsNumber} ${t("hbh.items.label")})`
                : ` (${t("hbh.noData.label")}) `}
            </ActionButton>
          </Grid>
        )}
      </Container>

      {manageDependentsOpen && employeeDetails && (
        <EmployeeDependentsDialog
          open={manageDependentsOpen}
          setOpen={onManageDependentsOpen}
          employeeData={employeeDetails}
        />
      )}
    </>
  );
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-top: 20px;
`;

const DependentCoverageTitle = styled.div`
  font-size: 20px;
  font-weight: 600;
  margin-top: 20px;
`;
