import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useAtom, useAtomValue } from "jotai";
import { useAppTheme } from "hooks/useAppTheme";
import { useTaxonomyOptions } from "hooks/useTaxonomyOptions";
import {
  EmployeeRowItem,
  ETaxonomy,
  IEmployee,
  IEmployeeDependent,
} from "library/types";
import {
  makeStyles,
  Button,
  MenuTrigger,
  Menu,
  MenuPopover,
  MenuList,
  MenuItem,
} from "@fluentui/react-components";
import {
  employeeFilterSideEffectAtom,
  expandedEmployeesAtom,
  filteredEmployeeViewsAtom,
  selectedCompanyState,
  selectedEmployeesAtom,
  selectedEmployeeState,
} from "store/UIHrPage";
import { colorList } from "../../colorList";
import GenericTree from "components/GenericTree/GenericTree";
import { AvatarWithPreview } from "components/AvatarWithPreview/AvatarWithPreview";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import {
  PersonEdit24Regular,
  Add24Regular,
  bundleIcon,
  CalendarMonthFilled,
  CalendarMonthRegular,
  Delete24Regular,
  Eye24Regular,
  SelectAllOn24Regular,
  SelectAllOff24Regular,
  Person24Filled,
  Person24Regular,
} from "@fluentui/react-icons";

import { DeleteIcon } from "@fluentui/react-icons-mdl2";
import { EMAIL_ID_SEPARATOR } from "library/utils";
import { useGetEmployeeDetails } from "state/queries/useGetEmployeeDetails";
import { useGetDependentDetails } from "state/queries/useGetDependentDetails";
import { AppSpinner } from "components/AppSpinner/AppSpinner";
import { EmployeeFormDialog } from "pages/HrPage/components/ManagePageView/EmployeesView/EmployeesTable/EmployeeActions/ManageEmployee/EmployeeFormDialog";
import { ConfirmationDialog } from "components/ConfirmationDialog/ConfirmationDialog";
import { useDeleteEmployee } from "state/queries/useDeleteEmployee";
import { useDeleteEmployeeDependent } from "state/queries/useDeleteEmployeeDependent";
import { toast } from "react-toastify";
import { EmployeeDependentsDialog } from "pages/HrPage/components/ManagePageView/EmployeesView/EmployeesTable/EmployeeActions/ManageEmployee/UpdateEmployeeFields/EmployeeDependentsDialog/EmployeeDependentsDialog";
import EmployeeTagsDialog from "../dialogs/EmployeeTags/EmployeeTagsDialog";
const CalendarMonth = bundleIcon(CalendarMonthFilled, CalendarMonthRegular);

/**
 * EmployeeTree Component - Uses GenericTree to render employee hierarchy.
 */
const EmployeeTree = () => {
  const { palette } = useAppTheme();
  const [selectedItems, setSelectedItems] = useAtom(selectedEmployeesAtom);
  const [expandedItems, setExpandedItems] = useAtom(expandedEmployeesAtom);
  const [companyEmployeeViews] = useAtom(filteredEmployeeViewsAtom);
  useAtom(employeeFilterSideEffectAtom); // This will trigger the side effects
  const dependentTypes = useTaxonomyOptions(ETaxonomy.DependentType);
  const [addEmployeeTagsOpen, setAddEmployeeTagsOpen] = useState(false);
  const [removeEmployeeTagsOpen, setRemoveEmployeeTagsOpen] = useState(false);
  const theme = useAppTheme();
  const selectedCompany = useAtomValue(selectedCompanyState);
  const { t } = useTranslation();
  const [selectedEmployee, setSelectedEmployee] = useAtom(
    selectedEmployeeState
  );
  const [employeeDetailsOpen, setEmployeeDetailsOpen] = useState(false);
  const [deleteConfirmationOpen, setDeleteConfirmationOpen] = useState(false);

  const [showTags, setShowTags] = useState(false);
  const [selectInProgress, setSelectInProgress] = useState(false);

  const { mutate: onDeleteEmployee, isLoading: deleteInProgress } =
    useDeleteEmployee();

  const handleAddEmployeeTagsDialogStateChange = useCallback(() => {
    setAddEmployeeTagsOpen((prev) => !prev);
  }, []);

  const handleRemoveEmployeeTagsDialogStateChange = useCallback(() => {
    setRemoveEmployeeTagsOpen((prev) => !prev);
  }, []);

  const {
    mutate: onDeleteEmployeeDependent,
    isLoading: deleteDependentInProgress,
  } = useDeleteEmployeeDependent();

  const handleDelete = useCallback(() => {
    if ((selectedEmployee as any)?.employeeDependentTag !== null) {
      onDeleteEmployeeDependent(
        {
          countryCode: Number(selectedCompany?.countryCode),
          clientInternalNumberGOS: Number(
            selectedCompany?.clientInternalNumberGos
          ),
          employeeLoginEmailAddress:
            (selectedEmployee as any)?.employeeLoginEmailAddress || "",
          employeeDependentTag: Number(
            (selectedEmployee as any)?.employeeDependentTag
          ),
          rowVersion: selectedEmployee?.rowVersion || "",
        },
        {
          onSuccess: () => {
            toast.success(
              t("hbh.employeeTree.confirmDeleteDependent.description")
            );
            setDeleteConfirmationOpen(false);
          },
        }
      );
      return;
    }
    onDeleteEmployee(
      {
        countryCode: Number(selectedCompany?.countryCode),
        clientInternalNumberGOS: Number(
          selectedCompany?.clientInternalNumberGos
        ),
        employeeEmail: selectedEmployee?.emailAddress || "",
        rowVersion: selectedEmployee?.rowVersion || "",
      },
      {
        onSuccess: () => {
          toast.success(
            t("hbh.employeeTree.confirmDeleteEmployee.description")
          );
          setDeleteConfirmationOpen(false);
        },
      }
    );
  }, [selectedEmployee, selectedCompany]);

  const handleSelectAll = () => {
    setSelectInProgress(true);
    const allIds = treeData.map((node) => node.id);
    setSelectedItems(new Set(allIds));
    setSelectInProgress(false);
  };

  const handleDeselectAll = () => {
    setSelectInProgress(true);
    setSelectedItems(new Set());
    setSelectInProgress(false);
  };

  const toggleDeleteConfirmationState = useCallback(() => {
    setDeleteConfirmationOpen((prev) => !prev);
  }, []);

  const { data: employeeData, isLoading: employeeLoading } =
    useGetEmployeeDetails({
      countryCode: Number(selectedCompany?.countryCode),
      clientInternalNumberGOS: Number(selectedCompany?.clientInternalNumberGos),
      employeeEmail: selectedEmployee?.employeeLoginEmailAddress || "",
      enabled:
        employeeDetailsOpen &&
        !!!(selectedEmployee as any)?.employeeDependentTag,
    });

  const { data: dependentData, isLoading: dependentLoading } =
    useGetDependentDetails({
      countryCode: Number(selectedCompany?.countryCode),
      clientInternalNumberGOS: Number(selectedCompany?.clientInternalNumberGos),
      employeeLoginEmailAddress:
        (selectedEmployee as any)?.employeeLoginEmailAddress || "",
      employeeDependentTag: Number(
        (selectedEmployee as any)?.employeeDependentTag
      ),
      enabled:
        employeeDetailsOpen &&
        !!(selectedEmployee as any)?.employeeDependentTag,
    });

  const handleDialogStateChange = useCallback(() => {
    setEmployeeDetailsOpen((prev) => !prev);
  }, []);

  const packageUserData = selectedEmployee?.isDependent
    ? dependentData
    : employeeData;

  const treeData = React.useMemo(() => {
    const result: any[] = [];

    companyEmployeeViews?.forEach((employee, index) => {
      const parentId = employee.employeeLoginEmailAddress + "";

      result.push({
        id: parentId,
        content: (
          <Container>
            <NameWrap>
              <AvatarWithPreview
                name={
                  employee.firstName +
                  " " +
                  employee.lastName +
                  " (" +
                  employee.emailAddress +
                  ")"
                }
                imgSrc={employee?.backgroundPicturePath}
              />
              <>
                {employee.firstName +
                  " " +
                  employee.lastName +
                  " (" +
                  employee.emailAddress +
                  ")"}
              </>
            </NameWrap>
            {showTags && (
              <TagsWrap>
                {employee.tags?.map((tag, index2) => (
                  <Tag
                    key={index2}
                    color={
                      Array.from(selectedItems).includes(parentId)
                        ? palette.themePrimary
                        : palette.themeLight
                    }
                  >
                    {tag}
                  </Tag>
                ))}
              </TagsWrap>
            )}
          </Container>
        ),
        parentId: undefined,
        dataItem: employee,
        hasActions: true,
        actions: [
          {
            label: "Edit Details",
            onClick: () => {
              setSelectedEmployee(employee);
              setEmployeeDetailsOpen(true);
            },
            icon: (
              <PersonEdit24Regular style={{ color: palette.themePrimary }} />
            ),
          },
          // {
          //   label: "Manage Package Eligibility",
          //   onClick: () => {},
          //   icon: (
          //     <PersonSquareCheckmark24Regular
          //       style={{ color: palette.themePrimary }}
          //     />
          //   ),
          // },
          {
            label: "Delete Employee",
            onClick: () => {
              setSelectedEmployee(employee);
              setDeleteConfirmationOpen(true);
            },
            icon: (
              <DeleteIcon
                style={{
                  color: palette.redDark,
                }}
              />
            ),
          },
        ],
      });

      employee.dependentList?.forEach((dependent, depIndex) => {
        result.push({
          id: `${parentId}###${dependent.employeeDependentTag}`,
          content: (
            <Container>
              <NameWrap>
                <AvatarWithPreview
                  name={
                    dependent.firstName +
                    " " +
                    dependent.lastName +
                    ` (${t(
                      dependentTypes.find(
                        (depType) => depType.value === dependent.dependentType
                      )?.code
                    )})`
                  }
                  imgSrc={dependent?.backgroundPicturePath}
                />
                <>
                  {dependent.firstName +
                    " " +
                    dependent.lastName +
                    ` (${t(
                      dependentTypes.find(
                        (depType) => depType.value === dependent.dependentType
                      )?.code
                    )})`}
                </>
              </NameWrap>
            </Container>
          ),
          parentId: parentId,
          dataItem: dependent,
          hasActions: true,
          actions: [
            {
              label: "Edit Details",
              onClick: () => {
                setSelectedEmployee(dependent);
                setEmployeeDetailsOpen(true);
              },
              icon: (
                <PersonEdit24Regular style={{ color: palette.themePrimary }} />
              ),
            },
            // {
            //   label: "Manage Package Eligibility",
            //   onClick: () => {},
            //   icon: (
            //     <PersonSquareCheckmark24Regular
            //       style={{ color: palette.themePrimary }}
            //     />
            //   ),
            // },
            {
              label: "Delete Dependent",
              onClick: () => {
                setSelectedEmployee(dependent);
                setDeleteConfirmationOpen(true);
              },
              icon: (
                <DeleteIcon
                  style={{
                    color: palette.redDark,
                  }}
                />
              ),
            },
          ],
        });
      });
    });

    return result;
  }, [companyEmployeeViews, dependentTypes, selectedItems, showTags]);

  const colorAssignments = useMemo(() => {
    const retVal: Record<string, any> = {};
    let colorGroupIndex = 0;
    let colorIndex = 0;

    companyEmployeeViews?.forEach((item) => {
      item.enrollments?.forEach((eligibility) => {
        const colorGroup = colorList[colorGroupIndex] ?? colorList[0];
        retVal[eligibility.benefitPackageTag] = {
          colorGroup: colorGroup?.toneGroupName ?? "defaultToneGroupName",
          color: null,
          hexValue: null,
          textColor: null,
        };

        colorIndex++;

        if (colorIndex >= (colorGroup?.colors.length ?? 0)) {
          colorIndex = 0;
          colorGroupIndex = (colorGroupIndex + 1) % colorList.length;
        }
      });
    });

    return retVal;
  }, [companyEmployeeViews]);

  return (
    <>
      {(deleteInProgress ||
        deleteDependentInProgress ||
        employeeLoading ||
        selectInProgress ||
        dependentLoading) && <AppSpinner />}
      <GenericTree
        treeData={treeData}
        onNodeSelect={(id, sItems) => setSelectedItems(sItems)}
        onNodeExpand={(id, eItems) => setExpandedItems(eItems)}
        colorAssignments={colorAssignments}
        selectedItems={selectedItems}
        expandedItems={expandedItems}
        rowHeight="auto"
        childRowHeight="auto"
        selectChildren={false}
        additionalButtons={
          <>
            <Menu>
              <MenuTrigger disableButtonEnhancement>
                <Button
                  icon={<SelectAllOn24Regular />}
                  size="small"
                  disabled={false}
                >
                  {t("hbh.employeeTree.select.label")}
                </Button>
              </MenuTrigger>
              <MenuPopover>
                <MenuList>
                  <MenuItem
                    icon={
                      <SelectAllOn24Regular
                        style={{
                          color: theme.palette.themePrimary,
                          transform: "scale(1.1)",
                        }}
                      />
                    }
                    onClick={handleSelectAll}
                  >
                    {t("hbh.employeeTree.selectAll.label")}
                  </MenuItem>
                  <MenuItem
                    icon={
                      <Person24Filled
                        style={{
                          color: theme.palette.themePrimary,
                          transform: "scale(1.1)",
                        }}
                      />
                    }
                    onClick={(e) => {
                      e.stopPropagation();
                      const allIds = treeData
                        .filter((node) => !node.id.includes(EMAIL_ID_SEPARATOR))
                        .map((node) => node.id);
                      setSelectedItems(new Set(allIds));
                    }}
                  >
                    Select Employees
                  </MenuItem>
                  <MenuItem
                    icon={
                      <Person24Regular
                        style={{
                          color: theme.palette.themePrimary,
                          transform: "scale(1.1)",
                        }}
                      />
                    }
                    onClick={(e) => {
                      e.stopPropagation();
                      const allIds = treeData
                        .filter((node) => node.id.includes(EMAIL_ID_SEPARATOR))
                        .map((node) => node.id);
                      setSelectedItems(new Set(allIds));
                    }}
                  >
                    Select Dependents
                  </MenuItem>
                  <MenuItem
                    icon={
                      <SelectAllOff24Regular
                        style={{
                          color: theme.palette.themePrimary,
                          transform: "scale(1.1)",
                        }}
                      />
                    }
                    onClick={handleDeselectAll}
                  >
                    {t("hbh.employeeTree.deselectAll.label")}
                  </MenuItem>
                </MenuList>
              </MenuPopover>
            </Menu>
            &nbsp;&nbsp;
            <Menu>
              <MenuTrigger disableButtonEnhancement>
                <Button icon={<CalendarMonth />} size="small">
                  {t("hbh.employeeTree.manageTags.label")}
                </Button>
              </MenuTrigger>
              <MenuPopover>
                <MenuList>
                  <MenuItem
                    icon={
                      <Eye24Regular
                        style={{
                          color: theme.palette.themePrimary,
                          transform: "scale(1.1)",
                        }}
                      />
                    }
                    onClick={(e) => {
                      e.stopPropagation();
                      setShowTags((prev) => !prev);
                    }}
                  >
                    {t("hbh.employeeTree.toggleTags.label")}
                  </MenuItem>
                  <MenuItem
                    icon={
                      <Add24Regular
                        style={{
                          color: theme.palette.themePrimary,
                          transform: "scale(1.1)",
                          opacity:
                            Array.from(selectedItems).filter(
                              (id) => !id.includes(EMAIL_ID_SEPARATOR)
                            ).length === 0
                              ? 0.5
                              : 1,
                        }}
                      />
                    }
                    onClick={(e) => {
                      e.stopPropagation();
                      setAddEmployeeTagsOpen(true);
                    }}
                    disabled={
                      Array.from(selectedItems).filter(
                        (id) => !id.includes(EMAIL_ID_SEPARATOR)
                      ).length === 0
                    }
                  >
                    {t("hbh.employeeTree.addTags.label")}
                  </MenuItem>
                  <MenuItem
                    icon={
                      <Delete24Regular
                        style={{
                          color: theme.palette.themePrimary,
                          transform: "scale(1.1)",
                          opacity:
                            Array.from(selectedItems).filter(
                              (id) => !id.includes(EMAIL_ID_SEPARATOR)
                            ).length === 0
                              ? 0.5
                              : 1,
                        }}
                      />
                    }
                    onClick={(e) => {
                      e.stopPropagation();
                      setRemoveEmployeeTagsOpen(true);
                    }}
                    disabled={
                      Array.from(selectedItems).filter(
                        (id) => !id.includes(EMAIL_ID_SEPARATOR)
                      ).length === 0
                    }
                  >
                    {t("hbh.employeeTree.removeTags.label")}
                  </MenuItem>
                </MenuList>
              </MenuPopover>
            </Menu>
          </>
        }
      />
      {employeeDetailsOpen && !selectedEmployee?.isDependent && (
        <EmployeeFormDialog
          item={packageUserData as EmployeeRowItem}
          open={employeeDetailsOpen}
          setOpen={handleDialogStateChange}
          isAddEmployee={false}
        />
      )}
      {employeeDetailsOpen && selectedEmployee?.isDependent && (
        <EmployeeDependentsDialog
          singleDependentEdit
          employeeData={employeeData as EmployeeRowItem}
          open={employeeDetailsOpen}
          setOpen={handleDialogStateChange}
          dependentData={selectedEmployee}
        />
      )}
      {deleteConfirmationOpen && (
        <ConfirmationDialog
          isOpen={deleteConfirmationOpen}
          toggleOpen={toggleDeleteConfirmationState}
          onConfirm={handleDelete}
          description={t("hbh.employeeTree.confirmDeleteEmployee.description")}
          confirmLabel={t(
            "hbh.employeeTree.confirmDeleteEmployee.confirmLabel"
          )}
          title={t("hbh.employeeTree.confirmDeleteEmployee.title", {
            name: `${selectedEmployee?.firstName} ${selectedEmployee?.lastName}`,
          })}
        />
      )}
      {(addEmployeeTagsOpen || removeEmployeeTagsOpen) &&
        Array.from(selectedItems).filter(
          (id) => !id.includes(EMAIL_ID_SEPARATOR)
        ).length > 0 && (
          <EmployeeTagsDialog
            employeeEmails={Array.from(selectedItems).filter(
              (id) => !id.includes(EMAIL_ID_SEPARATOR)
            )}
            open={addEmployeeTagsOpen || removeEmployeeTagsOpen}
            operation={addEmployeeTagsOpen ? "add" : "remove"}
            setOpen={
              addEmployeeTagsOpen
                ? handleAddEmployeeTagsDialogStateChange
                : handleRemoveEmployeeTagsDialogStateChange
            }
            onSuccess={() => {}}
          />
        )}
    </>
  );
};

export default EmployeeTree;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 5px;
  padding: 2px;
  width: 100%;
`;

const NameWrap = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  gap: 5px;
`;

const TagsWrap = styled.div`
  display: flex;
  align-items: left;
  width: 100%;
  gap: 5px;
`;

const Tag = styled.div<{ color: string }>`
  background: ${({ color }) => color};
  color: white;
  padding: 2px 5px;
  border-radius: 5px;
`;
