import { APP_ID } from "library/constants";
import { EAppSettings } from "library/types";
import { useGetUserAppSettings } from "state/queries/useGetUserAppSettings";

type Props = {
  targetSettingName: EAppSettings;
};

export function useGetUserAppTargetSetting({ targetSettingName }: Props) {
  const { data: userAppSettings } = useGetUserAppSettings({
    appId: APP_ID,
  });

  const targetSetting = userAppSettings?.find(
    (setting) => setting.userAppSettingName === targetSettingName
  );

  return targetSetting;
}
