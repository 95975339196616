import { Button, Checkbox } from "@fluentui/react-components";
import { ArrowUndoFilled } from "@fluentui/react-icons";
import { DeleteIcon, SaveIcon } from "@fluentui/react-icons-mdl2";
import { ConfirmationDialog } from "components/ConfirmationDialog/ConfirmationDialog";
import SelectField from "components/controls/SelectField";
import { useAppTheme } from "hooks/useAppTheme";
import { useTaxonomyOptions } from "hooks/useTaxonomyOptions";
import { useAtomValue, useSetAtom } from "jotai";
import { devices } from "library/constants";
import { ETaxonomy, IUserAssignment, SelectItem } from "library/types";
import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import { useCreateUserAssignment } from "state/queries/useCreateUserAssignment";
import { useDeleteUserAssignment } from "state/queries/useDeleteUserAssignment";
import { useUpdateUserAssignment } from "state/queries/useUpdateUserAssignment";
import {
  searchUserInputOptionAtom,
  searchUserInputValueAtom,
  selectedCompanyState,
} from "store/UIHrPage";
import styled from "styled-components";

type Props = {
  user?: IUserAssignment;
  selectedUser?: IUserAssignment | null;
  onSelectUser?: (user: IUserAssignment | null) => void;
};

type IAddUserState = {
  isInternal: boolean;
  isReadOnly: boolean;
  role: SelectItem | null;
};

export function ManageUserAssignment({
  user,
  selectedUser,
  onSelectUser,
}: Props) {
  const { t } = useTranslation();
  const theme = useAppTheme();
  const selectedCompany = useAtomValue(selectedCompanyState);

  const [deleteConfirmationOpen, setDeleteConfirmationOpen] = useState(false);

  const setSearchUserInputValue = useSetAtom(searchUserInputValueAtom);

  const setSearchUserOption = useSetAtom(searchUserInputOptionAtom);

  const [userFormState, setUserFormState] = useState({
    isInternal: false,
    isReadOnly: false,
    role: null,
  } as IAddUserState);

  const handleUpdateUserFormState = ({ key, value }) => {
    setUserFormState((prev) => {
      return {
        ...prev,
        [key]: value,
      };
    });
  };

  const { mutate: onCreateUserAssignment, isLoading: createInProgress } =
    useCreateUserAssignment();
  const { mutate: onUpdateUserAssignment, isLoading: updateInProgress } =
    useUpdateUserAssignment();

  const { mutate: onDeleteUserAssign, isLoading: deleteInProgress } =
    useDeleteUserAssignment();

  const toggleDeleteConfirmationState = useCallback(() => {
    setDeleteConfirmationOpen((prev) => !prev);
  }, []);

  const roleOptions = useTaxonomyOptions(ETaxonomy.HbhUserRoleType);

  const loading = createInProgress || updateInProgress || deleteInProgress;

  const addEditDisabled =
    Object.keys(userFormState).some(
      (key) => userFormState[key] === null || userFormState[key] === undefined
    ) || loading;

  const handleManageUser = () => {
    if (user) {
      onUpdateUserAssignment(
        {
          countryCode: Number(user?.countryCode),
          clientInternalNumberGOS: Number(user?.clientInternalNumberGos),
          hbhUserAssignmentId: Number(user?.hbhUserAssignmentId),
          payload: {
            userId: user?.userId,
            userName: user?.userName,
            userEmailAddress: user?.userEmailAddress,
            hbhUserRole: Number(userFormState.role?.value),
            isInternal: userFormState.isInternal,
            isReadOnly: userFormState.isReadOnly,
            rowVersion: user?.rowVersion,
          },
        },
        {
          onSuccess: () => {
            toast.success(t("hbh.userAssignmentUpdated.label"));
          },
        }
      );
      return;
    }
    onCreateUserAssignment(
      {
        countryCode: Number(selectedCompany?.countryCode),
        clientInternalNumberGOS: Number(
          selectedCompany?.clientInternalNumberGos
        ),
        payload: {
          ...selectedUser,
          hbhUserRole: Number(userFormState.role?.value),
          isInternal: userFormState.isInternal,
          isReadOnly: userFormState.isReadOnly,
        },
      },
      {
        onSuccess: () => {
          toast.success(t("hbh.userAssignmentAdded.label"));
          onSelectUser && onSelectUser(null);
          setSearchUserInputValue("");
          setSearchUserOption(null);
        },
      }
    );
  };

  const handleDeleteUserAssignment = useCallback(() => {
    onDeleteUserAssign(
      {
        countryCode: Number(user?.countryCode),
        clientInternalNumberGOS: Number(user?.clientInternalNumberGos),
        hbhUserAssignmentId: Number(user?.hbhUserAssignmentId),
        payload: {
          rowVersion: user?.rowVersion || "",
        },
      },
      {
        onSuccess: () => {
          toggleDeleteConfirmationState();
          toast.success(t("hbh.userAssignmentDeleted.label"));
        },
      }
    );
  }, [user]);

  const setDefaultUserFormState = (user) => {
    setUserFormState({
      isInternal: Boolean(user.isInternal),
      isReadOnly: Boolean(user.isReadOnly),
      role: roleOptions.find((role) => role.value === user.hbhUserRole) || null,
    });
  };

  useEffect(() => {
    if (user) {
      setDefaultUserFormState(user);
    }
  }, [user]);

  const hasChange =
    user &&
    JSON.stringify({
      isInternal: Boolean(user.isInternal),
      isReadOnly: Boolean(user.isReadOnly),
      role: roleOptions.find((role) => role.value === user.hbhUserRole) || null,
    }) !== JSON.stringify(userFormState);

  return (
    <>
      <Container>
        <Checkbox
          disabled={loading}
          checked={userFormState.isInternal}
          onChange={(ev, data) =>
            handleUpdateUserFormState({
              key: "isInternal",
              value: data.checked,
            })
          }
          label={t("hbh.isInternal.label")}
        />
        <Checkbox
          disabled={loading}
          checked={userFormState.isReadOnly}
          onChange={(ev, data) =>
            handleUpdateUserFormState({
              key: "isReadOnly",
              value: data.checked,
            })
          }
          label={t("hbh.limited.label")}
        />
        <div>
          <SelectField
            disabled={loading}
            error={""}
            setFieldValue={(_, value) => {
              handleUpdateUserFormState({
                key: "role",
                value: value,
              });
            }}
            style={{
              maxWidth: "300px",
              minWidth: "300px",
            }}
            value={userFormState.role as any}
            noLabel
            options={roleOptions}
            name={"role"}
            showEmptyOption
            placeholder={t("hbh.selectRole.label")}
            noErrorSection
          />
        </div>
        <Button
          disabled={addEditDisabled || (user && !hasChange)}
          onClick={handleManageUser}
          style={{
            color: theme.palette.themePrimary,

            width: "fit-content",
            marginLeft: "20px",
          }}
          icon={<SaveIcon />}
          appearance="outline"
        />
        {user && (
          <>
            <Button
              onClick={() => {
                setDefaultUserFormState(user);
              }}
              disabled={loading || !hasChange}
              icon={<ArrowUndoFilled />}
            />
            <Button
              disabled={loading}
              style={{
                color: theme.palette.redDark,
              }}
              onClick={() => {
                setDeleteConfirmationOpen(true);
              }}
              icon={<DeleteIcon />}
            />
          </>
        )}
      </Container>
      {deleteConfirmationOpen && (
        <ConfirmationDialog
          isOpen={deleteConfirmationOpen}
          toggleOpen={toggleDeleteConfirmationState}
          onConfirm={handleDeleteUserAssignment}
          description={t("hbh.deleteObject.label", {
            object: user?.userName,
          })}
          isDisabled={deleteInProgress}
          confirmLabel={t("greco.delete")}
          title={`${t("hbh.deleteUserAssignment.label")}: ${user?.userName} `}
        />
      )}
    </>
  );
}

const Container = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;

  gap: 10px;

  @media only screen and ${devices.md} {
    flex-direction: row;
  }
`;
