import { Card, Text, Title3 } from "@fluentui/react-components";
import {
  BarElement,
  CategoryScale,
  Chart as ChartJS,
  Legend,
  LinearScale,
  Title,
  Tooltip,
} from "chart.js";
import { AppSpinner } from "components/AppSpinner/AppSpinner";
import { useAtom, useAtomValue } from "jotai";
import moment from "moment";
import { useEffect, useMemo } from "react";
import { Bar } from "react-chartjs-2";
import { useGetCompanyInfos } from "state/queries/useGetCompanyInfos";
import { selectedCompanyState } from "store/UIHrPage";
import {
  filterCurrencyAtom,
  filterPaymentMethodAtom,
  filterPaymentTypeAtom,
  historicReportAtom,
  reportDateAtom,
  selectedBenefitsAtom,
} from "store/UIHrPageDashboard";
import styled from "styled-components";
import FilterSection from "./FilterSection/FilterSection";
import { formattedNumber, vibrantColors } from "./utils";
import { devices } from "library/constants";
import { useTranslation } from "react-i18next";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

const Dashboard = () => {
  const { t } = useTranslation();
  const selectedCompany = useAtomValue(selectedCompanyState);
  const [reportDate, setReportDate] = useAtom(reportDateAtom);
  const historicReport = useAtomValue(historicReportAtom);
  const [selectedBenefits, setSelectedBenefits] = useAtom(selectedBenefitsAtom);
  const filterPaymentMethod = useAtomValue(filterPaymentMethodAtom);
  const filterPaymentType = useAtomValue(filterPaymentTypeAtom);
  const filterCurrency = useAtomValue(filterCurrencyAtom);
  const {
    data: companyInfo,
    isFetching: loadingInfo,
    refetch,
  } = useGetCompanyInfos({
    countryCode: selectedCompany?.countryCode || null,
    clientInternalNumberGOS: selectedCompany?.clientInternalNumberGos || "",
    onDate: historicReport ? moment(reportDate).format("YYYY-MM-DD") : "now",
    enabled: !!selectedCompany,
  });

  useEffect(() => {
    setSelectedBenefits(new Set([]));
    refetch && refetch();
  }, [reportDate, selectedCompany, historicReport]);

  const filteredCompanyInfo = useMemo(() => {
    if (!companyInfo) return null;
    const implementationsWithGivenCurrency = companyInfo?.benefitImplementations
      ?.filter((imp) => imp.currencyCode + "" === filterCurrency?.value + "")
      .map((imp) => imp.benefitImplementationTag);

    const selectedCategoryTags = Array.from(selectedBenefits)
      .filter((b) => b.includes("_package"))
      .map((p, index) => {
        const categoryTag = companyInfo?.benefitPackages?.find(
          (bp) =>
            bp.benefitPackageTag + "" === (p + "").replaceAll("_package", "")
        )?.benefitCategoryTag;
        return categoryTag;
      });
    // make list of unique category tags
    const uniqueCategoryTags = Array.from(new Set(selectedCategoryTags));
    // make list of categries based on unique category tags
    const selectedCategories = companyInfo?.benefitCategories?.filter((bc) =>
      uniqueCategoryTags.includes(bc.benefitCategoryTag)
    );

    const filteredEmployeeEnrollments = companyInfo?.employeeEnrollments
      ?.filter((en) =>
        Array.from(selectedBenefits).includes(en.benefitPackageTag + "_package")
      )
      .filter((en) => {
        return en.dateOfEnrollment !== null;
      })
      .filter((en) => {
        return implementationsWithGivenCurrency?.includes(
          (en as any).benefitImplementationTag
        );
      })
      .filter(
        (en) =>
          filterPaymentMethod?.value === "" ||
          en.paymentMethod === filterPaymentMethod?.value
      )
      .filter(
        (en) =>
          filterPaymentType?.value === "" ||
          en.paymentType === filterPaymentType?.value
      );
    const retVal = {
      ...companyInfo,
      filteredEmployeeEnrollments: filteredEmployeeEnrollments,
      companyCosts: filteredEmployeeEnrollments?.reduce((accumulator, en) => {
        return (
          accumulator +
          (en.usingPercentagesForPaidBy
            ? (en.paidByCompany / 100) * en.price
            : en.paidByCompany)
        );
      }, 0),
      employeeCosts: filteredEmployeeEnrollments?.reduce((accumulator, en) => {
        return (
          accumulator +
          (en.usingPercentagesForPaidBy
            ? (en.paidByEmployee / 100) * en.price
            : en.paidByEmployee)
        );
      }, 0),
      // count unique employees from enrollments (excluding dependents)
      employeesCount: Array.from(
        new Set(
          filteredEmployeeEnrollments
            ?.filter((en) => en.employeeDependentTag === null)
            .map((en) => en.employeeLoginEmailAddress)
        )
      ).length,
      totalEmployeesCount: companyInfo?.employees?.length,
      // count unique dependents from enrollments
      dependentsCount: Array.from(
        new Set(
          filteredEmployeeEnrollments
            ?.filter((en) => en.employeeDependentTag !== null)
            .map((en) => en.employeeDependentTag)
        )
      ).length,
      totalDependentsCount: companyInfo?.employees?.reduce(
        (accumulator, employee) => {
          return (
            accumulator + (employee.dependents ? employee.dependents.length : 0)
          );
        },
        0
      ),
      // grouped by category, implementation, package
      paidByEmployeeChartData: {
        labels: ["For Employees", "For Dependents"],
        datasets: selectedCategories?.map((category, index) => {
          const color = vibrantColors[index];
          return {
            label: (category as any).name,
            backgroundColor: color.backgroundColor,
            borderColor: color.borderColor,
            borderWidth: 1,
            data: [
              filteredEmployeeEnrollments
                ?.filter(
                  (en) =>
                    (en as any).benefitCategoryTag ===
                      category?.benefitCategoryTag && en.dependentType === null
                )
                ?.reduce((accumulator, en) => {
                  return (
                    accumulator +
                    (en.usingPercentagesForPaidBy
                      ? (en.paidByEmployee / 100) * en.price
                      : en.paidByEmployee)
                  );
                }, 0),
              filteredEmployeeEnrollments
                ?.filter(
                  (en) =>
                    (en as any).benefitCategoryTag ===
                      category?.benefitCategoryTag && en.dependentType !== null
                )
                ?.reduce((accumulator, en) => {
                  return (
                    accumulator +
                    (en.usingPercentagesForPaidBy
                      ? (en.paidByEmployee / 100) * en.price
                      : en.paidByEmployee)
                  );
                }, 0),
            ],
          };
        }),
      },
      paidByCompanyChartData: {
        labels: ["For Employees", "For Dependents"],
        datasets: selectedCategories?.map((category, index) => {
          const color = vibrantColors[index];
          return {
            label: (category as any).name,
            backgroundColor: color.backgroundColor,
            borderColor: color.borderColor,
            borderWidth: 1,
            data: [
              filteredEmployeeEnrollments
                ?.filter(
                  (en) =>
                    (en as any).benefitCategoryTag ===
                      category?.benefitCategoryTag && en.dependentType === null
                )
                ?.reduce((accumulator, en) => {
                  return (
                    accumulator +
                    (en.usingPercentagesForPaidBy
                      ? (en.paidByCompany / 100) * en.price
                      : en.paidByCompany)
                  );
                }, 0),
              filteredEmployeeEnrollments
                ?.filter(
                  (en) =>
                    (en as any).benefitCategoryTag ===
                      category?.benefitCategoryTag && en.dependentType !== null
                )
                ?.reduce((accumulator, en) => {
                  return (
                    accumulator +
                    (en.usingPercentagesForPaidBy
                      ? (en.paidByCompany / 100) * en.price
                      : en.paidByCompany)
                  );
                }, 0),
            ],
          };
        }),
      },
    };
    return retVal;
  }, [
    companyInfo,
    selectedBenefits,
    filterCurrency,
    filterPaymentMethod,
    filterPaymentType,
  ]);

  return (
    <Container>
      <FilterSection companyInfo={companyInfo} isLoading={loadingInfo} />
      <Content>
        <ContentWrap>
          <div>
            <Card style={{ padding: "20px" }}>
              <Title3>
                {t("hbh.dashboard.employees.label")}: (
                {(filteredCompanyInfo?.employeesCount ?? "0") +
                  "/" +
                  (filteredCompanyInfo?.totalEmployeesCount ?? "0")}
                )
              </Title3>
              <Title3>
                {t("hbh.dashboard.dependents.label")}: (
                {(filteredCompanyInfo?.dependentsCount ?? "0") +
                  "/" +
                  (filteredCompanyInfo?.totalDependentsCount ?? "0")}
                )
              </Title3>
            </Card>
          </div>
          <div>
            <Card style={{ padding: "20px" }}>
              <Title3>
                {t("hbh.dashboard.companyCosts.label")}:{" "}
                {filteredCompanyInfo?.companyCosts
                  ? formattedNumber.format(
                      filteredCompanyInfo?.companyCosts as number
                    )
                  : null}
              </Title3>
              <Title3>
                {t("hbh.dashboard.employeeCosts.label")}:{" "}
                {filteredCompanyInfo?.employeeCosts
                  ? formattedNumber.format(
                      filteredCompanyInfo?.employeeCosts as number
                    )
                  : null}
              </Title3>
            </Card>
          </div>
        </ContentWrap>
        <ContentWrap>
          <Card style={{ padding: "20px" }}>
            <Text>{t("hbh.dashboard.paidByEmployee.label")}</Text>
            {loadingInfo || !filteredCompanyInfo ? (
              <div
                style={{
                  height: `calc(100vh - ${600 - 44}px)`,
                  overflowY: "scroll",
                }}
              />
            ) : (
              <Bar
                data={{
                  labels:
                    filteredCompanyInfo?.paidByEmployeeChartData?.labels ?? [],
                  datasets:
                    filteredCompanyInfo?.paidByEmployeeChartData?.datasets ??
                    [],
                }}
                options={{
                  responsive: true,
                  plugins: { legend: { position: "top" } },
                }}
              />
            )}
          </Card>
          <Card style={{ padding: "20px" }}>
            <Text>{t("hbh.dashboard.paidByCompany.label")}</Text>
            {loadingInfo || !filteredCompanyInfo ? (
              <div
                style={{
                  height: `calc(100vh - ${600 - 44}px)`,
                  overflowY: "scroll",
                }}
              />
            ) : (
              <Bar
                data={{
                  labels:
                    filteredCompanyInfo?.paidByCompanyChartData?.labels ?? [],
                  datasets:
                    filteredCompanyInfo?.paidByCompanyChartData?.datasets ?? [],
                }}
                options={{
                  responsive: true,
                  plugins: { legend: { position: "top" } },
                }}
              />
            )}
          </Card>
        </ContentWrap>
      </Content>
      {loadingInfo && <AppSpinner />}
    </Container>
  );
};

export default Dashboard;

const Container = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  gap: 20px;
  padding: 20px;

  @media only screen and ${devices.md} {
    grid-template-columns: 1fr 3fr;
  }
`;
const Content = styled.div`
  display: grid;
  grid-template-rows: 1fr;
  gap: 20px;

  @media only screen and ${devices.md} {
    grid-template-rows: auto 1fr;
  }
`;

const ContentWrap = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  gap: 20px;

  @media only screen and ${devices.md} {
    grid-template-columns: 1fr 1fr;
  }
`;
