import { EAppSettings, SelectItem } from "library/types";
import { ChangeEvent } from "react";
import { ISettingsStrings } from "./ISettingsStrings";

import {
  SelectOnChangeData,
  Switch,
  SwitchOnChangeData,
  SwitchProps,
} from "@fluentui/react-components";
import SelectField from "components/controls/SelectField";
import { Flex } from "components/styled";
import { ViewSelectionToggle } from "components/ViewSelectionToggle/ViewSelectionToggle";
import { useGetUserAppTargetSetting } from "hooks/useGetUserAppTargetSetting";
import { APP_ID, APP_SETTING_ID } from "library/constants";
import { useTranslation } from "react-i18next";
import { useAddAppSetting } from "state/queries/useAddAppSetting";
import { useUpdateAppSettings } from "state/queries/useUpdateAppSettings";
import styled from "styled-components";
import { ThemeSelector } from "./ThemeSelector/ThemeSelector";

export interface ISettingsToggle {
  toggleProps: SwitchProps;
  title: string;
}

interface IProps {
  darkMode?: any;
  strings?: ISettingsStrings;
  languageOptions?: SelectItem[];
  defaultSelectedLanguage?: string;
  onChangeLanguage?: (
    ev: ChangeEvent<HTMLSelectElement>,
    data: SelectOnChangeData
  ) => void;
}

export const Settings = (props: IProps) => {
  const { t } = useTranslation();

  const darkModeAppSetting = useGetUserAppTargetSetting({
    targetSettingName: EAppSettings.darkMode,
  });

  const { mutate: onAddAppSetting } = useAddAppSetting();
  const { mutate: onUpdateAppSetting } = useUpdateAppSettings();

  return (
    <Container>
      {props.languageOptions && (
        <SelectField
          label={t("header.SelectLanguage")}
          appearance="filled-darker"
          name="selectValue"
          value={
            props.languageOptions.find(
              (option) => option.value === props.defaultSelectedLanguage
            ) as any
          }
          aria-placeholder="Select language"
          // defaultSelectedKey={props.defaultSelectedLanguage}
          options={props.languageOptions}
          setFieldValue={props.onChangeLanguage as any}
        />
      )}
      <Flex direction="column" gap={"25px"} align="flex-start">
        <Switch
          defaultChecked={darkModeAppSetting?.userAppSettingValue === "true"}
          onChange={(_, data: SwitchOnChangeData) => {
            props.darkMode && props.darkMode(data.checked);

            if (darkModeAppSetting) {
              onUpdateAppSetting({
                payload: [
                  {
                    ...darkModeAppSetting,
                    userAppSettingValue: data.checked.toString(),
                  },
                ] as any,
              });
            } else {
              onAddAppSetting({
                payload: [
                  {
                    applicationCodeId: APP_ID,
                    userAppSettingValue: data.checked.toString(),
                    appSettingCodeId: APP_SETTING_ID,
                    userAppSettingName: EAppSettings.darkMode,
                    isDefault: false,
                  },
                ],
              });
            }
          }}
          label={
            (props.strings && props.strings.darkModeToggleLabel) ||
            t("header.DarkMode")
          }
          indicator={{
            style: {
              marginLeft: 0,
            },
          }}
          labelPosition="after"
        />
        <ThemeSelector />
        <ViewSelectionToggle />
      </Flex>
    </Container>
  );
};

const Container = styled.div``;
