import { ETaxonomy, SelectItem } from "library/types";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useGetTaxonomies } from "state/queries/useGetTaxonomies";

export function useTaxonomyOptions(taxonomy: ETaxonomy, isSicCode = false) {
  const { t } = useTranslation();
  const { data: taxonomies } = useGetTaxonomies();

  const sortCallback = (a, b) => {
    if (isSicCode) {
      return a.text.split(" ")[1].localeCompare(b.text.split(" ")[1]);
    }

    return a.text.localeCompare(b.text);
  };
  const options = useMemo(() => {
    if (!taxonomies) return [];
    return taxonomies[taxonomy]
      ?.map((item) => {
        const text = (() => {
          if (isSicCode) {
            const sicCodeCode = item.code.split(".")[1];

            return `${sicCodeCode} ${t(item.code)}`;
          }
          return t(item.code);
        })();
        return {
          text,
          code: item.code,
          value: item.id,
        } as SelectItem;
      })
      .sort(sortCallback);
  }, [taxonomies, taxonomy, isSicCode, t]);

  return options;
}
