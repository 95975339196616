import {
  Breadcrumb,
  BreadcrumbButton,
  BreadcrumbDivider,
  BreadcrumbItem,
} from "@fluentui/react-components";
import { BuildingRegular, CertificateRegular } from "@fluentui/react-icons";
import { FlagIcon } from "@fluentui/react-icons-mdl2";
import { CompanyMobileBreadcrumb } from "components/CompanyMobileBreadcrumb/CompanyMobileBreadcrumb";
import { SearchBoxField } from "components/controls/SearchBoxField";
import { ResultsScore } from "components/ResultsScore/ResultsScore";
import { useIsMobile } from "hooks/useIsMobile";
import { useAtom, useAtomValue } from "jotai";
import { devices, ROUTES } from "library/constants";
import { IBenefitImplementation } from "library/types";
import { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import {
  implementationSearchFilterValueState,
  selectedCompanyState,
  selectedCountryState,
} from "store/UICompanyPage";
import styled from "styled-components";
// import { isListViewState } from "store/UIAdminPage";

type Props = {
  items: IBenefitImplementation[];
  disabled?: boolean;
  categoryName?: string;
};

export function CompanyImplementationSubheader({
  items,
  disabled,
  categoryName,
}: Props) {
  const { t } = useTranslation();
  const isMobile = useIsMobile();
  const navigate = useNavigate();
  const [searchFilterValue, setSearchFilterValue] = useAtom(
    implementationSearchFilterValueState
  );

  const [selectedCompany, setSelectedCompany] = useAtom(selectedCompanyState);
  const selectedCountry = useAtomValue(selectedCountryState);

  const handleSearch = useCallback((value) => {
    setSearchFilterValue(value.toLowerCase());
  }, []);

  const mobileBreadcrumbItems = [
    {
      key: 0,
      item: selectedCountry?.text,
      itemProps: {
        icon: <FlagIcon />,
      },
      onClick: () => {
        setSelectedCompany(null);
        navigate(ROUTES.COMPANY_PROFILE);
      },
    },
    {
      key: 1,
      item: selectedCompany?.label,
      itemProps: {
        icon: <BuildingRegular />,
      },
      onClick: () => {
        navigate(ROUTES.COMPANY_PROFILE);
      },
    },
    {
      key: 2,
      item: categoryName,
      itemProps: {
        icon: <CertificateRegular />,
      },
    },
  ];

  return (
    <Container>
      <Wrap>
        {/* <Navigation /> */}

        <SearchBoxField
          disabled={disabled}
          value={searchFilterValue}
          appearance="outline"
          placeholder={`${t("header.Search")}...`}
          onSearch={handleSearch}
          width={isMobile ? "max-content" : "360px"}
        />
        {isMobile ? (
          <CompanyMobileBreadcrumb items={mobileBreadcrumbItems} />
        ) : (
          <Breadcrumb aria-label="Breadcrumb default example">
            <BreadcrumbItem>
              <BreadcrumbButton
                icon={<FlagIcon />}
                onClick={() => {
                  setSelectedCompany(null);

                  navigate(ROUTES.COMPANY_PROFILE);
                }}
              >
                {selectedCountry?.text}
              </BreadcrumbButton>
            </BreadcrumbItem>
            <BreadcrumbDivider />
            <BreadcrumbItem>
              <BreadcrumbButton
                icon={<BuildingRegular />}
                onClick={() => {
                  navigate(ROUTES.COMPANY_PROFILE);
                }}
              >
                {selectedCompany?.label}
              </BreadcrumbButton>
            </BreadcrumbItem>
            <BreadcrumbItem>
              <BreadcrumbButton disabled icon={<CertificateRegular />}>
                {categoryName}
              </BreadcrumbButton>
            </BreadcrumbItem>
          </Breadcrumb>
        )}
      </Wrap>
      <ResultsScore score={items?.length || 0} />
    </Container>
  );
}

const Container = styled.div<{ $showSearchFilter?: boolean }>`
  display: flex;
  width: 100%;
  padding: 10px;
  margin: auto;
  position: sticky;
  z-index: 99;
  top: 0px;
  height: ${(props) => (props.$showSearchFilter ? "auto" : "52px")};
  background-color: ${(props) => props.theme.palette.white};
  box-sizing: border-box;
  justify-content: space-between;
  gap: 5px;
  @media only screen and ${devices.md} {
    padding: 10px 30px;
  }
`;

const Wrap = styled.div`
  display: flex;
  flex-wrap: nowrap;
  gap: 5px;
  @media only screen and ${devices.md} {
  }
`;
