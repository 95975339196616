import { Button, Spinner } from "@fluentui/react-components";
import { DeleteIcon } from "@fluentui/react-icons-mdl2";
import { AppSpinner } from "components/AppSpinner/AppSpinner";
import { ConfirmationDialog } from "components/ConfirmationDialog/ConfirmationDialog";
import { Flex, StyledTooltip } from "components/styled";
import { useAppTheme } from "hooks/useAppTheme";
import { useAtomValue } from "jotai";
import {
  EmployeeRowItem,
  IBenefitPackageView,
  IEmployeeEnrollment,
  IEmployeeEnrollmentBase,
  IEmployeeView,
} from "library/types";
import { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import { useDeleteEmployeeDependentEnrollment } from "state/queries/useDeleteEmployeeDependentEnrollment";
import { useDeleteEmployeeEnrollment } from "state/queries/useDeleteEmployeeEnrollment";

type Props = {
  packageData?: IBenefitPackageView;
  enrollment?: IEmployeeEnrollmentBase;
  packageUser?: IEmployeeView;
  disabled?: boolean;
};

export function DeleteEmployeeEnrollment({
  packageData,
  enrollment,
  packageUser,
  disabled,
}: Props) {
  const { t } = useTranslation();
  const { palette } = useAppTheme();

  const [deleteConfirmationOpen, setDeleteConfirmationOpen] = useState(false);

  const { mutate: onDeleteEmployeeEnrollment, isLoading: deleteInProgress } =
    useDeleteEmployeeEnrollment();

  const {
    mutate: onDeleteDependentEnrollment,
    isLoading: deleteDependentEnrProgress,
  } = useDeleteEmployeeDependentEnrollment();

  const handleDelete = useCallback(() => {
    if (packageUser?.isDependent) {
      onDeleteDependentEnrollment(
        {
          countryCode: Number(packageData?.countryCode),
          clientInternalNumberGOS: Number(packageData?.clientInternalNumberGos),
          benefitPackageTag: Number(packageData?.benefitPackageTag),
          employeeLoginEmailAddress:
            packageUser?.employeeLoginEmailAddress || "",
          employeeDependentTag: Number(packageUser?.employeeDependentTag),
          employeeEnrollmentTag: Number(enrollment?.employeeEnrollmentTag),
          rowVersion: enrollment?.rowVersion || "",
        },
        {
          onSuccess: () => {
            toast.success(t("hbh.enrollment.delete.success"));
            setDeleteConfirmationOpen(false);
          },
        }
      );
      return;
    }

    onDeleteEmployeeEnrollment(
      {
        countryCode: Number(packageData?.countryCode),
        clientInternalNumberGOS: Number(packageData?.clientInternalNumberGos),
        benefitPackageTag: Number(packageData?.benefitPackageTag),
        employeeLoginEmailAddress: packageUser?.employeeLoginEmailAddress || "",
        employeeEnrollmentTag: Number(enrollment?.employeeEnrollmentTag),
        rowVersion: enrollment?.rowVersion || "",
      },
      {
        onSuccess: () => {
          toast.success(t("hbh.enrollment.delete.success"));

          setDeleteConfirmationOpen(false);
        },
      }
    );
  }, [
    enrollment?.rowVersion,
    onDeleteDependentEnrollment,
    onDeleteEmployeeEnrollment,
    packageData?.benefitPackageTag,
    packageData?.clientInternalNumberGos,
    packageData?.countryCode,
    packageUser?.employeeDependentTag,
    packageUser?.employeeLoginEmailAddress,
    packageUser?.isDependent,
    t,
  ]);

  const toggleDeleteConfirmationState = useCallback(() => {
    setDeleteConfirmationOpen((prev) => !prev);
  }, []);

  return (
    <>
      {deleteInProgress ? (
        <>
          <Spinner size="extra-small" />
          <AppSpinner />
        </>
      ) : (
        <Flex width={"100%"} justify="center">
          <StyledTooltip
            relationship="label"
            content={{
              children: t("greco.delete"),
            }}
          >
            <Button
              icon={
                <DeleteIcon
                  style={{
                    ...(!disabled ? { color: palette.redDark } : {}),
                  }}
                />
              }
              disabled={
                deleteInProgress || deleteDependentEnrProgress || disabled
              }
              appearance="transparent"
              size="small"
              onClick={() => {
                setDeleteConfirmationOpen(true);
              }}
            />
          </StyledTooltip>
        </Flex>
      )}
      {deleteConfirmationOpen && (
        <ConfirmationDialog
          isOpen={deleteConfirmationOpen}
          toggleOpen={toggleDeleteConfirmationState}
          onConfirm={handleDelete}
          description={t("hbh.deleteUserPackageEnrollment.label", {
            userName: `${packageUser?.firstName} ${packageUser?.lastName}`,
            packageName: packageData?.name,
          })}
          confirmLabel="Delete"
          title={t("hbh.enrollment.delete.title", {
            selectedPackage: packageData?.name,
          })}
        />
      )}
    </>
  );
}
