import { AxiosError } from "axios";
import { MUTATION_KEYS, QUERY_KEYS } from "library/shared";
import { IClient } from "library/types";
import { handleAxiosError } from "library/utils";
import { useMutation, useQueryClient } from "react-query";
import api from "service/api";

type Params = {
  countryCode: number | string;
  clientInternalNumberGOS: number | string;
  payload: IClient;
};

const updateClient = async ({
  countryCode,
  clientInternalNumberGOS,
  payload,
}: Params): Promise<IClient> => {
  const response = await api.patch(
    `/client/${countryCode}/${clientInternalNumberGOS}`,
    {
      ...payload,
    }
  );

  return response.data as IClient;
};

export function useUpdateClient() {
  const queryClient = useQueryClient();

  return useMutation(updateClient, {
    mutationKey: MUTATION_KEYS.updateClient,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: QUERY_KEYS.benefitCategories });
      // queryClient.refetchQueries({ queryKey: QUERY_KEYS.benefitCategories });
    },
    onError: (error: AxiosError) => {
      handleAxiosError();
    },
  });
}
