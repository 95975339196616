import i18n from "../../i18n";

import Logo from "../../assets/GrECo_Logo_small.jpg";

import { logout } from "../../service/GraphService";
import { getHeaderConfig } from "./utils";

import { useTranslation } from "react-i18next";

import GrecoHeader from "components/GrecoHeader/GrecoHeader";
import { NavigationContent } from "components/Navigation/NavigationContent";
import { useGetUserAppTargetSetting } from "hooks/useGetUserAppTargetSetting";
import useNotificationHandler from "hooks/useNotificationHandler";
import { useSetAtom } from "jotai";
import { APP_ID, APP_SETTING_ID } from "library/constants";
import { EAppSettings, SelectItem } from "library/types";
import { useEffect } from "react";
import { useAddAppSetting } from "state/queries/useAddAppSetting";
import { useDeleteAppSettings } from "state/queries/useDeleteAppSettings";
import { useUpdateAppSettings } from "state/queries/useUpdateAppSettings";
import { useUserData } from "state/queries/useUserData";
import { buildYupLocale } from "validation";
import { panelIsOpenState } from "../../store";

const AppHeader = () => {
  const { t } = useTranslation();
  const { notifications, handleClearAllNotifications } =
    useNotificationHandler();

  const setPanelIsOpen = useSetAtom(panelIsOpenState);

  const { data: user } = useUserData();

  const { mutate: onAddAppSetting } = useAddAppSetting();
  const { mutate: onUpdateAppSetting } = useUpdateAppSettings();
  const { mutate: onDeleteAppSettings } = useDeleteAppSettings();
  const languageAppSetting = useGetUserAppTargetSetting({
    targetSettingName: EAppSettings.language,
  });

  const headerConfig = getHeaderConfig(t("hbh.appName.label"));

  useEffect(() => {
    // api.get("/uad/taxonomy");
  }, []);

  return (
    <GrecoHeader
      navigation={<NavigationContent />}
      headerConfig={headerConfig}
      panelStatus={setPanelIsOpen}
      user={user}
      notifications={notifications}
      clearNotifications={handleClearAllNotifications}
      logo={Logo}
      logout={logout}
      languageOptions={[
        {
          value: "en-GB",
          text: t("Language.EN_GB"),
        },
        {
          value: "de-AT",
          text: t("Language.DE_AT"),
        },
      ]}
      defaultLanguage={i18n.resolvedLanguage}
      onChangeLanguage={async (__event: any, languageOption?: SelectItem) => {
        if (!languageOption) return;

        i18n.changeLanguage(languageOption.value as string);

        if (languageAppSetting) {
          onUpdateAppSetting({
            payload: [
              {
                ...languageAppSetting,
                userAppSettingValue: languageOption?.value?.toString() || "",
              },
            ] as any,
          });
        } else {
          onAddAppSetting({
            payload: [
              {
                applicationCodeId: APP_ID,
                userAppSettingValue: languageOption?.value?.toString() || "",
                appSettingCodeId: APP_SETTING_ID,
                userAppSettingName: EAppSettings.language,
                isDefault: false,
              },
            ],
          });
        }

        buildYupLocale(null, t);
      }}
      headerStrings={{
        notificationsPanelStrings: {
          panelTitle: t("header.Notifications"),
          noNotificationsTitle: t("header.NoNotificationsTitle"),
          noNotificationsSubtitle: t("header.NoNotificationsSubtitle"),
        },
        settingsPanelStrings: {
          panelTitle: t("header.Settings"),
          darkModeToggleLabel: t("header.DarkMode"),
          selectLanguageLabel: t("header.SelectLanguage"),
        },
        userSettingsPanelStrings: {
          panelTitle: t("header.UserSettings"),
          myOfficeProfileLinkText: t("header.MyOfficeProfile"),
          myAccountLinkText: t("header.UserSettings"),
          signOutLinkText: t("header.SignOut"),
        },
      }}
    />
  );
};

export default AppHeader;
