import { MUTATION_KEYS, QUERY_KEYS } from "library/shared";
import { IEmployeeDependent, IEmployeeEnrollment } from "library/types";
import { handleAxiosError } from "library/utils";
import { useMutation, useQueryClient } from "react-query";
import api from "service/api";
import { useInvalidateCompanyEmployeeViews } from "./useGetCompanyEmployeeViews";

type Params = {
  countryCode: number | string;
  clientInternalNumberGOS: number | string;
  payload: IEmployeeEnrollment;
  benefitPackageTag: number | string;
  employeeLoginEmailAddress: string;
  employeeEnrollmentTag: number | string;
};

const updateEmployeeEnrollment = async ({
  countryCode,
  clientInternalNumberGOS,
  benefitPackageTag,
  employeeLoginEmailAddress,
  employeeEnrollmentTag,
  payload,
}: Params) => {
  const response = await api.patch(
    `/employeeenrollment/${countryCode}/${clientInternalNumberGOS}/${benefitPackageTag}/${employeeLoginEmailAddress}/${employeeEnrollmentTag}`,
    payload
  );

  return response.data as IEmployeeDependent;
};

export function useUpdateEmployeeEnrollment(refetch = true) {
  const queryClient = useQueryClient();
  const invalidateCompanyEmployees = useInvalidateCompanyEmployeeViews();

  return useMutation(updateEmployeeEnrollment, {
    mutationKey: MUTATION_KEYS.updateEmployeeEnrollment,
    onSuccess: () => {
      if (refetch) {
        invalidateCompanyEmployees();
        queryClient.invalidateQueries({
          queryKey: QUERY_KEYS.employeeDetails,
        });
        queryClient.invalidateQueries({
          queryKey: QUERY_KEYS.employeeEnrollmentsByEmployee,
        });
        queryClient.invalidateQueries({
          queryKey: QUERY_KEYS.employeeEnrollmentsByPackage,
        });
      }
    },
    onError: (error) => {
      // handle error
      handleAxiosError(error);
    },
  });
}
