export function HeaderTick() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      version="1.1"
      className="greco-header__tick"
      width="24"
      height="24"
      style={{
        fill: "#fff",
        left: "0px",
        position: "absolute",
        top: "0px",
        zIndex: 1,
      }}
    >
      <path d="M 0,0 24,0 0,12 z" />
    </svg>
  );
}
