import {
  Button,
  Menu,
  MenuItem,
  MenuList,
  MenuPopover,
  MenuTrigger,
} from "@fluentui/react-components";
import {
  CollectionsRegular,
  PersonEdit24Regular,
  PersonSquareCheckmark24Regular,
  SelectAllOff24Regular,
  SelectAllOn24Regular,
} from "@fluentui/react-icons";
import { DeleteIcon } from "@fluentui/react-icons-mdl2";
import { ConfirmationDialog } from "components/ConfirmationDialog/ConfirmationDialog";
import GenericTree from "components/GenericTree/GenericTree";
import { useAppTheme } from "hooks/useAppTheme";
import { useTaxonomyOptions } from "hooks/useTaxonomyOptions";
import { useAtom, useAtomValue } from "jotai";
import {
  ETaxonomy,
  IBenefitPackageView,
  IEmployeeEnrollment,
  IEmployeeView,
} from "library/types";
import { EMAIL_ID_SEPARATOR, isInteger } from "library/utils";
import { EligibilityFormDialog } from "pages/HrPage/components/ManagePageView/EmployeesView/EmployeesTable/EmployeeActions/EmployeeEligibility/EligibilityFormDialog/EligibilityFormDialog";
import { EnrollmentsFormDialog } from "pages/HrPage/components/ManagePageView/EmployeesView/EmployeesTable/SubrowComponent/EmployeeEnrollmentsActions/EnrollmentsFormDialog/EnrollmentsFormDialog";
import React, { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import { useDeleteEmployeeDependentEnrollment } from "state/queries/useDeleteEmployeeDependentEnrollment";
import { useDeleteEmployeeEnrollment } from "state/queries/useDeleteEmployeeEnrollment";
import { useDeleteEmployeeEnrollments } from "state/queries/useDeleteEmployeeEnrollments";
import {
  benefitsFilterSideEffectAtom,
  companyEmployeeViewsAtom,
  expandedBenefitsAtom,
  filteredCompanyPackagesAtom,
  selectedBenefitsAtom,
  selectedCompanyState,
  selectedEmployeesAtom,
  selectedEmployeeState,
  selectedEnrollmentState,
  selectedPackageState,
} from "store/UIHrPage";
import { colorList } from "../../colorList";
import { EmployeeEligibilityFormDialog } from "../dialogs/EmployeeEligibilityFormDialog/EmployeeEligibilityFormDialog";
import { EmployeeEnrollmentFormDialog } from "../dialogs/EmployeeEnrollmentsFormDialog/EmployeeEnrollmentFormDialog";
import {
  generateEnrollmentNode,
  generatePackageNode,
} from "./benefitsTreeUtil";
import { useUpdateEmployeeDependentEnrollment } from "state/queries/useUpdateEmployeeDependentEnrollment";
import { useUpdateEmployeeEnrollment } from "state/queries/useUpdateEmployeeEnrollment";
import { useUpdateEmployeeEnrollments } from "state/queries/useUpdateEmployeeEnrollments";
import { useInvalidateEmployeeEnrollment } from "state/queries/useGetEmployeeEnrollment";
import { useInvalidateDependentEnrollment } from "state/queries/useGetDependentEnrollment";
import { EmployeeCancelEnrollmentFormDialog } from "../dialogs/EmployeeCancelEnrollmentsFormDialog/EmployeeCancelEnrollmentFormDialog";

/**
 * BenefitsTree Component - Uses GenericTree to render benefits hierarchy.
 */
const BenefitsTree = () => {
  const theme = useAppTheme();
  const [selectedPackages, setSelectedPackages] = useAtom(selectedBenefitsAtom);
  const [expandedItems, setExpandedItems] = useAtom(expandedBenefitsAtom);

  const [companyPackages] = useAtom(filteredCompanyPackagesAtom);
  const employeeViews = useAtomValue(companyEmployeeViewsAtom);
  const selectedEmployees = useAtomValue(selectedEmployeesAtom);
  useAtom(benefitsFilterSideEffectAtom); // This will trigger the side effects

  const selectedCompany = useAtomValue(selectedCompanyState);

  const dependentTypes = useTaxonomyOptions(ETaxonomy.DependentType);
  const { t } = useTranslation();
  const { palette } = useAppTheme();
  const [addEligibilityOpen, setAddEligibilityOpen] = useState(false);
  const [selectedPackage, setSelectedPackage] = useAtom(selectedPackageState);
  const [selectedEmployee, setSelectedEmployee] = useAtom(
    selectedEmployeeState
  );
  const [selectedEnrollment, setSelectedEnrollment] = useAtom(
    selectedEnrollmentState
  );

  const [manageEligibilityOpen, setManageEligibilityOpen] =
    React.useState(false);
  const [manageEnrollmentOpen, setManageEnrollmentOpen] = React.useState(false);
  const [cancelEnrollmentOpen, setCancelEnrollmentOpen] = React.useState(false);

  const [editEmployeeOpen, setEditEmployeeOpen] = useState(false);
  const [deleteEnrollmentOpen, setDeleteEnrollmentOpen] = useState(false);
  const [deleteEnrollmentsOpen, setDeleteEnrollmentsOpen] = useState(false);
  const [removeEnrollments, setRemoveEnrollments] = useState(false);
  const setEditEmployeeOpenState = useCallback((state) => {
    setEditEmployeeOpen(state);
  }, []);

  const [selectInProgress, setSelectInProgress] = useState(false);

  const { mutate: onUpdateEmployeeEnrollment, isLoading: updateInProgress } =
    useUpdateEmployeeEnrollment();

  const {
    mutate: onUpdateDependentEnrollment,
    isLoading: updateDependentEnrolmentInProgress,
  } = useUpdateEmployeeDependentEnrollment();

  const { mutate: onUpdateEmployeeEnrollments, isLoading: updatesInProgress } =
    useUpdateEmployeeEnrollments();

  const { mutate: onDeleteEmployeeEnrollment, isLoading: deleteInProgress } =
    useDeleteEmployeeEnrollment();

  const {
    mutate: onDeleteDependentEnrollment,
    isLoading: deleteDependentEnrProgress,
  } = useDeleteEmployeeDependentEnrollment();

  const invalidateEmployeeEnrollment = useInvalidateEmployeeEnrollment();
  const invalidateDependentEnrollment = useInvalidateDependentEnrollment();

  const { mutate: onDeleteEmployeeEnrollments, isLoading: deletesInProgress } =
    useDeleteEmployeeEnrollments();

  const handleSelectAll = () => {
    setSelectInProgress(true);
    // Select all
    const allIds = treeData
      .filter((node) => node.useSelectBox)
      .map((node) => node.id);
    setSelectedPackages(new Set(allIds));
    setSelectInProgress(false);
  };

  const handleDeselectAll = () => {
    setSelectInProgress(true);
    // Deselect all
    setSelectedPackages(new Set());
    setSelectInProgress(false);
  };

  const setRemoveEnrollmentOpenState = useCallback(() => {
    setDeleteEnrollmentOpen((prev) => !prev);
  }, []);

  const setDeleteEnrollmentsOpenState = useCallback(() => {
    setDeleteEnrollmentsOpen((prev) => !prev);
  }, []);

  const handleDialogStateChange = useCallback(() => {
    setAddEligibilityOpen((prev) => !prev);
  }, []);

  const employeeEnrollments = React.useMemo(() => {
    const result: any = {};
    employeeViews?.forEach((employee) => {
      if (!employee.employeeLoginEmailAddress) return;
      employee.enrollments?.forEach((enrollment) => {
        result[
          employee.employeeLoginEmailAddress +
            EMAIL_ID_SEPARATOR +
            enrollment.benefitPackageTag
        ] = {
          ...enrollment,
          dateOfEmployment: employee.dateOfEmployment,
          name: `${employee.firstName} ${employee.lastName}`,
        };
      });
      employee.dependentList?.forEach((dependent) => {
        dependent.enrollments?.forEach((enrollment) => {
          result[
            dependent.employeeLoginEmailAddress +
              EMAIL_ID_SEPARATOR +
              dependent.employeeDependentTag +
              EMAIL_ID_SEPARATOR +
              enrollment.benefitPackageTag
          ] = {
            ...enrollment,
            name: `${dependent.firstName} ${dependent.lastName} (${t(
              dependentTypes.find(
                (depType) => depType.value === dependent.dependentType
              )?.code
            )})`,
          };
        });
      });
    });
    return result;
  }, [employeeViews, dependentTypes]);

  const treeData = React.useMemo(() => {
    let result: any[] = [];

    [...companyPackages]
      .sort((a, b) => (a.benefitCategoryTag ?? 0) - (b.benefitCategoryTag ?? 0))
      ?.forEach((benefitPackage, index) => {
        result.push(
          generatePackageNode({
            benefitPackage,
            palette,
            setters: {
              setSelectedPackage,
              setDeleteEnrollmentsOpen,
              setRemoveEnrollments,
              setManageEligibilityOpen,
              setManageEnrollmentOpen,
              setCancelEnrollmentOpen,
              setSelectedEmployee,
              setSelectedEnrollment,
            },
            t,
          })
        );

        result.sort((a, b) => {
          return a.dataItem?.id - b.dataItem?.id;
        });
        //if the package is selected
        if (
          Array.from(selectedPackages).includes(
            benefitPackage.benefitPackageTag + ""
          )
        ) {
          selectedEmployees.forEach((employee) => {
            const enrollment =
              employeeEnrollments[
                employee + EMAIL_ID_SEPARATOR + benefitPackage.benefitPackageTag
              ];

            // if (enrollment) {
            const enrolledPackage = enrollment;
            const packageData = benefitPackage;
            let packageUser: any = null;
            if (employee.lastIndexOf(EMAIL_ID_SEPARATOR) === -1) {
              // employee
              packageUser = employeeViews?.find(
                (emp) => emp.employeeLoginEmailAddress === employee
              );
            } else {
              // dependent
              const [employeeLoginEmailAddress, employeeDependentTag] =
                employee.split(EMAIL_ID_SEPARATOR);
              packageUser = employeeViews
                ?.find(
                  (emp) =>
                    emp.employeeLoginEmailAddress === employeeLoginEmailAddress
                )
                ?.dependentList?.find(
                  (dep) =>
                    dep.employeeDependentTag + "" === employeeDependentTag
                );
            }
            result.push(
              generateEnrollmentNode({
                employee,
                enrollment,
                enrolledPackage,
                packageData,
                packageUser,
                palette,
                dependentTypes,
                t,
                setters: {
                  setAddEligibilityOpen,
                  setEditEmployeeOpen,
                  setManageEnrollmentOpen,
                  setRemoveEnrollmentOpen: setDeleteEnrollmentOpen,
                  setCancelEnrollmentOpen,
                  setSelectedPackage,
                  setSelectedEmployee,
                  setSelectedEnrollment,
                  setRemoveEnrollments,
                },
                invalidateEmployeeEnrollment,
                invalidateDependentEnrollment,
              })
            );
          });
        }
      });

    return result;
  }, [
    companyPackages,
    employeeEnrollments,
    selectedEmployees,
    removeEnrollments,
    selectedPackages,
    employeeViews,
  ]);

  const handleDeleteEnrollment = useCallback(() => {
    if ((selectedEmployee as any)?.employeeDependentTag !== null) {
      if (removeEnrollments) {
        onUpdateEmployeeEnrollments(
          {
            countryCode: Number(selectedCompany?.countryCode),
            clientInternalNumberGOS: Number(
              selectedCompany?.clientInternalNumberGos
            ),

            enrollments: [
              {
                ...selectedEnrollment,
                benefitPackageTag: Number(
                  selectedEnrollment?.benefitPackageTag
                ),
                employeeLoginEmailAddress:
                  (selectedEmployee as any)?.employeeLoginEmailAddress || "",
                employeeDependentTag: Number(
                  (selectedEmployee as any)?.employeeDependentTag
                ),
                dependentType: Number((selectedEmployee as any)?.dependentType),
                dateOfEnrollment: null,
                isAutoEnrollment: false,
              },
            ] as IEmployeeEnrollment[],
          },
          {
            onSuccess: () => {
              toast.success(
                removeEnrollments
                  ? t("hbh.enrollment.delete.success")
                  : t("hbh.eligibility.delete.success")
              );
              setDeleteEnrollmentOpen(false);
            },
          }
        );
      } else {
        onDeleteDependentEnrollment(
          {
            countryCode: Number(selectedCompany?.countryCode),
            clientInternalNumberGOS: Number(
              selectedCompany?.clientInternalNumberGos
            ),
            benefitPackageTag: Number(selectedEnrollment?.benefitPackageTag),
            employeeLoginEmailAddress:
              (selectedEmployee as any)?.employeeLoginEmailAddress || "",
            employeeDependentTag: Number(
              (selectedEmployee as any)?.employeeDependentTag
            ),
            employeeEnrollmentTag: Number(
              selectedEnrollment?.employeeEnrollmentTag
            ),
            rowVersion: selectedEnrollment?.rowVersion || "",
          },
          {
            onSuccess: () => {
              toast.success(
                removeEnrollments
                  ? t("hbh.enrollment.delete.success")
                  : t("hbh.eligibility.delete.success")
              );
              setDeleteEnrollmentOpen(false);
            },
          }
        );
      }
      return;
    } else {
      if (removeEnrollments) {
        onUpdateEmployeeEnrollments(
          {
            countryCode: Number(selectedCompany?.countryCode),
            clientInternalNumberGOS: Number(
              selectedCompany?.clientInternalNumberGos
            ),

            enrollments: [
              {
                ...selectedEnrollment,
                benefitPackageTag: Number(
                  selectedEnrollment?.benefitPackageTag
                ),
                employeeLoginEmailAddress:
                  (selectedEmployee as any)?.employeeLoginEmailAddress || "",
                dateOfEnrollment: null,
                isAutoEnrollment: false,
              },
            ],
          },
          {
            onSuccess: () => {
              toast.success(
                removeEnrollments
                  ? t("hbh.enrollment.delete.success")
                  : t("hbh.eligibility.delete.success")
              );
              setDeleteEnrollmentOpen(false);
            },
          }
        );
      } else {
        onDeleteEmployeeEnrollment(
          {
            countryCode: Number(selectedCompany?.countryCode),
            clientInternalNumberGOS: Number(
              selectedCompany?.clientInternalNumberGos
            ),
            benefitPackageTag: Number(selectedEnrollment?.benefitPackageTag),
            employeeLoginEmailAddress:
              (selectedEmployee as any)?.employeeLoginEmailAddress || "",
            employeeEnrollmentTag: Number(
              selectedEnrollment?.employeeEnrollmentTag
            ),
            rowVersion: selectedEnrollment?.rowVersion || "",
          },
          {
            onSuccess: () => {
              toast.success(
                removeEnrollments
                  ? t("hbh.enrollment.delete.success")
                  : t("hbh.eligibility.delete.success")
              );
              setDeleteEnrollmentOpen(false);
            },
          }
        );
      }
    }
  }, [
    selectedEnrollment,
    selectedEmployee,
    selectedCompany,
    removeEnrollments,
  ]);

  const handleDeleteEnrollments = useCallback(() => {
    let selectedPacks = Array.from(selectedPackages);
    if (selectedPackage) {
      selectedPacks = [selectedPackage.benefitPackageTag + ""];
    }
    let enrollments = treeData
      .filter((treeNode) => {
        return (
          selectedPacks.includes(treeNode.parentId + "") && treeNode.dataItem
        );
      })
      .map((node) => node.dataItem);

    enrollments = enrollments.filter((enrollment) => {
      // console.log(enrollment.dateOfEnrollment, cancelEnrollments);
      return removeEnrollments
        ? enrollment.dateOfEnrollment !== null
        : enrollment.dateOfEnrollment === null;
    });
    if (removeEnrollments) {
      onUpdateEmployeeEnrollments(
        {
          countryCode: Number(selectedCompany?.countryCode),
          clientInternalNumberGOS: Number(
            selectedCompany?.clientInternalNumberGos
          ),
          enrollments: enrollments.map((enrollment) => ({
            ...enrollment,
            dateOfEnrollment: null,
            isAutoEnrollment: false,
          })),
        },
        {
          onSuccess: () => {
            toast.success("Employee Enrollments deleted successfully");
            setDeleteEnrollmentsOpen(false);
          },
        }
      );
    } else {
      onDeleteEmployeeEnrollments(
        {
          countryCode: Number(selectedCompany?.countryCode),
          clientInternalNumberGOS: Number(
            selectedCompany?.clientInternalNumberGos
          ),
          enrollments: enrollments,
        },
        {
          onSuccess: () => {
            toast.success("Employee Enrollments deleted successfully");
            setDeleteEnrollmentsOpen(false);
          },
        }
      );
    }
  }, [selectedPackage, treeData]);

  const colorAssignments = React.useMemo(() => {
    const retVal: any = {};
    let colorGroupIndex = 0;
    let colorIndex = 0;

    companyPackages.forEach((item) => {
      const colorGroup = colorList[colorGroupIndex];

      if (item.benefitPackageTag) {
        retVal[item.benefitPackageTag] = {
          colorGroup: colorGroup
            ? colorGroup.toneGroupName
            : "defaultToneGroupName",
          color: null,
          hexValue: null,
          textColor: null,
        };
      }
      colorIndex++;

      if (colorIndex >= (colorGroup?.colors.length ?? 0)) {
        colorIndex = 0;
        colorGroupIndex = (colorGroupIndex + 1) % colorList.length;
      }
    });

    return retVal;
  }, [companyPackages]);

  return (
    <>
      <GenericTree
        treeData={treeData}
        onNodeSelect={(id, sItems) => {
          if (isInteger(id)) setSelectedPackages(sItems);
        }}
        onNodeExpand={(id, eItems) => setExpandedItems(eItems)}
        colorAssignments={colorAssignments}
        selectedItems={selectedPackages}
        expandedItems={expandedItems}
        rowHeight="auto"
        childRowHeight="auto"
        useExpandCollapse={true}
        useSelectBoxes={false}
        selectChildren={false}
        levelIndent={56}
        additionalButtons={
          <>
            <Menu>
              <MenuTrigger disableButtonEnhancement>
                <Button
                  icon={<SelectAllOn24Regular />}
                  size="small"
                  disabled={false}
                >
                  {t("hbh.select.label")}
                </Button>
              </MenuTrigger>
              <MenuPopover>
                <MenuList>
                  <MenuItem
                    icon={
                      <SelectAllOn24Regular
                        style={{
                          color: theme.palette.themePrimary,
                          transform: "scale(1.1)",
                        }}
                      />
                    }
                    onClick={handleSelectAll}
                  >
                    {t("hbh.selectAll.label")}
                  </MenuItem>

                  <MenuItem
                    icon={
                      <SelectAllOff24Regular
                        style={{
                          color: theme.palette.themePrimary,
                          transform: "scale(1.1)",
                        }}
                      />
                    }
                    onClick={handleDeselectAll}
                  >
                    {t("hbh.deselectAll.label")}
                  </MenuItem>
                </MenuList>
              </MenuPopover>
            </Menu>
            &nbsp;&nbsp;
            {Array.from(selectedPackages).length > 0 ? (
              <Menu>
                <MenuTrigger disableButtonEnhancement>
                  <Button
                    icon={<CollectionsRegular />}
                    size="small"
                    disabled={false}
                  >
                    {t("hbh.bulkActions.label")}
                  </Button>
                </MenuTrigger>
                <MenuPopover>
                  <MenuList>
                    <MenuItem
                      icon={
                        <PersonEdit24Regular
                          style={{
                            color: theme.palette.themePrimary,
                            transform: "scale(1.1)",
                          }}
                        />
                      }
                      onClick={() => {
                        setSelectedPackage(null);
                        setManageEligibilityOpen(true);
                      }}
                    >
                      {t("hbh.enrollment.makeEligible.label")}
                    </MenuItem>
                    <MenuItem
                      icon={
                        <PersonSquareCheckmark24Regular
                          style={{
                            color: theme.palette.themePrimary,
                            transform: "scale(1.1)",
                          }}
                        />
                      }
                      onClick={() => {
                        setSelectedPackage(null);
                        setManageEnrollmentOpen(true);
                      }}
                    >
                      {t("hbh.enrollment.enrollEligible.label")}
                    </MenuItem>

                    <MenuItem
                      icon={
                        <DeleteIcon
                          style={{
                            color: palette.redDark,
                          }}
                        />
                      }
                      onClick={() => {
                        setRemoveEnrollments(false);
                        setSelectedPackage(null);
                        setDeleteEnrollmentsOpen(true);
                      }}
                    >
                      {t("hbh.enrollment.removeEligibilities.label")}
                    </MenuItem>
                    <MenuItem
                      icon={
                        <DeleteIcon
                          style={{
                            color: palette.redDark,
                          }}
                        />
                      }
                      onClick={() => {
                        setRemoveEnrollments(true);
                        setSelectedPackage(null);
                        setDeleteEnrollmentsOpen(true);
                      }}
                    >
                      {t("hbh.enrollment.cancelEnrollments.label")}
                    </MenuItem>
                  </MenuList>
                </MenuPopover>
              </Menu>
            ) : null}
          </>
        }
      />
      {addEligibilityOpen && (
        <EligibilityFormDialog
          presetValues={{
            package: {
              ...selectedPackage,
              text: selectedPackage?.name,
              label: selectedPackage?.name,
              value: selectedPackage?.benefitPackageTag,
            },
          }} //package is used as select item so needs to have text/label/value
          benefitPackageTag={selectedPackage?.benefitPackageTag}
          employeeEnrollmentTag={selectedEnrollment?.employeeEnrollmentTag}
          packageUser={
            {
              ...selectedEmployee,
              dateOfEmployment:
                employeeEnrollments[
                  selectedEmployee?.employeeLoginEmailAddress +
                    EMAIL_ID_SEPARATOR +
                    selectedPackage?.benefitPackageTag
                ]?.dateOfEmployment ??
                employeeViews?.find(
                  (el) =>
                    el.employeeLoginEmailAddress ===
                    selectedEmployee?.employeeLoginEmailAddress
                )?.dateOfEmployment,
            } as IEmployeeView
          }
          open={addEligibilityOpen}
          setOpen={handleDialogStateChange}
        />
      )}
      {editEmployeeOpen && (
        <EnrollmentsFormDialog
          open={editEmployeeOpen}
          setOpen={setEditEmployeeOpenState}
          packageData={selectedPackage as IBenefitPackageView}
          packageUser={selectedEmployee as IEmployeeView}
          employeeEnrollment={selectedEnrollment as IEmployeeEnrollment}
        />
      )}
      {deleteEnrollmentOpen && (
        <ConfirmationDialog
          isOpen={deleteEnrollmentOpen}
          toggleOpen={setRemoveEnrollmentOpenState}
          onConfirm={handleDeleteEnrollment}
          description={t(
            removeEnrollments
              ? t("hbh.enrollments.delete.confirm")
              : t("hbh.eligibilities.delete.confirm"),
            {
              employeeName:
                (selectedEmployee as any)?.firstName +
                " " +
                (selectedEmployee as any)?.lastName,
              packageName: selectedPackage
                ? (selectedPackage as any)?.name
                : t("hbh.enrollment.selectedPackages.label"),
            }
          )}
          confirmLabel={t("greco.apply")}
          title={t(
            removeEnrollments
              ? t("hbh.enrollment.delete.title")
              : t("hbh.eligibility.delete.title"),
            {
              selectedPackage: selectedPackage
                ? (selectedPackage as any)?.name
                : t("hbh.enrollment.selectedPackages.label"),
            }
          )}
          isDisabled={
            deleteInProgress ||
            deleteDependentEnrProgress ||
            updateInProgress ||
            updateDependentEnrolmentInProgress
          }
        />
      )}
      {deleteEnrollmentsOpen && (
        <ConfirmationDialog
          isOpen={deleteEnrollmentsOpen}
          toggleOpen={setDeleteEnrollmentsOpenState}
          onConfirm={handleDeleteEnrollments}
          description={t(
            removeEnrollments
              ? t("hbh.enrollments.deleteall.confirm")
              : t("hbh.eligibilities.deleteall.confirm"),
            {
              packageName: selectedPackage
                ? (selectedPackage as any)?.name
                : t("hbh.enrollment.selectedPackages.label"),
            }
          )}
          confirmLabel={t(
            removeEnrollments ? t("hbh.cancel.label") : t("hbh.remove.label")
          )}
          title={t(
            removeEnrollments
              ? t("hbh.enrollment.deleteall.title")
              : t("hbh.eligibility.deleteall.title"),
            {
              selectedPackage: selectedPackage
                ? (selectedPackage as any)?.name
                : t("hbh.enrollment.selectedPackages.label"),
            }
          )}
          isDisabled={deletesInProgress || updatesInProgress}
        />
      )}
      {manageEligibilityOpen ||
      (selectedPackage
        ? treeData.filter((treeNode) => {
            return (
              treeNode.parentId === selectedPackage?.benefitPackageTag + "" &&
              !treeNode.dataItem
            );
          }).length > 0
        : treeData.filter((treeNode) => {
            return (
              Array.from(selectedPackages).includes(treeNode.parentId + "") &&
              !treeNode.dataItem
            );
          }).length > 0) ? (
        <EmployeeEligibilityFormDialog
          open={manageEligibilityOpen}
          setOpen={setManageEligibilityOpen}
          employeeItems={Array.from(selectedEmployees)}
          enrollmentItems={
            selectedPackage
              ? treeData.filter((treeNode) => {
                  return (
                    treeNode.parentId ===
                      selectedPackage?.benefitPackageTag + "" &&
                    !treeNode.dataItem
                  );
                })
              : treeData.filter((treeNode) => {
                  return (
                    Array.from(selectedPackages).includes(
                      treeNode.parentId + ""
                    ) && !treeNode.dataItem
                  );
                })

            // .filter((enrollment) => {
            //   return enrollment.dateOfEnrollment === null;
            // })
          }
          benefitItems={
            selectedPackage
              ? Array.from([selectedPackage.benefitPackageTag + ""])
              : Array.from(selectedPackages)
          }
        />
      ) : null}
      {manageEnrollmentOpen ||
      (selectedPackage
        ? treeData.filter((treeNode) => {
            return (
              treeNode.parentId === selectedPackage?.benefitPackageTag + "" &&
              treeNode.dataItem &&
              treeNode.dataItem.dateOfEnrollment === null
            );
          }).length > 0
        : treeData.filter((treeNode) => {
            return (
              Array.from(selectedPackages).includes(treeNode.parentId + "") &&
              treeNode.dataItem &&
              treeNode.dataItem.dateOfEnrollment === null
            );
          }).length > 0) ? (
        <EmployeeEnrollmentFormDialog
          open={manageEnrollmentOpen}
          setOpen={setManageEnrollmentOpen}
          employeeItems={
            selectedEmployee
              ? [selectedEmployee]
              : Array.from(selectedEmployees)
          }
          enrollmentItems={(selectedPackage
            ? treeData
                .filter((treeNode) => {
                  return (
                    treeNode.parentId ===
                      selectedPackage?.benefitPackageTag + "" &&
                    treeNode.dataItem
                  );
                })
                .filter((treeNode) => {
                  if (!selectedEmployee) return true;
                  return (
                    treeNode.dataItem.employeeLoginEmailAddress ===
                      selectedEmployee?.employeeLoginEmailAddress &&
                    treeNode.dataItem.employeeDependentTag ===
                      selectedEmployee?.employeeDependentTag
                  );
                })
            : treeData.filter((treeNode) => {
                return (
                  Array.from(selectedPackages).includes(
                    treeNode.parentId + ""
                  ) && treeNode.dataItem
                );
              })
          )
            .map((node) => node.dataItem)
            .filter((enrollment) => {
              return enrollment.dateOfEnrollment === null;
            })}
          benefitItems={
            selectedPackage
              ? Array.from([selectedPackage.benefitPackageTag + ""])
              : Array.from(selectedPackages)
          }
        />
      ) : null}
      {cancelEnrollmentOpen ||
      (selectedPackage
        ? treeData.filter((treeNode) => {
            return (
              treeNode.parentId === selectedPackage?.benefitPackageTag + "" &&
              treeNode.dataItem &&
              treeNode.dataItem.dateOfEnrollment !== null
            );
          }).length > 0
        : treeData.filter((treeNode) => {
            return (
              Array.from(selectedPackages).includes(treeNode.parentId + "") &&
              treeNode.dataItem &&
              treeNode.dataItem.dateOfEnrollment !== null
            );
          }).length > 0) ? (
        <EmployeeCancelEnrollmentFormDialog
          open={cancelEnrollmentOpen}
          setOpen={setCancelEnrollmentOpen}
          employeeItems={
            selectedEmployee
              ? [selectedEmployee]
              : Array.from(selectedEmployees)
          }
          enrollmentItems={(selectedPackage
            ? treeData
                .filter((treeNode) => {
                  return (
                    treeNode.parentId ===
                      selectedPackage?.benefitPackageTag + "" &&
                    treeNode.dataItem
                  );
                })
                .filter((treeNode) => {
                  if (!selectedEmployee) return true;
                  return (
                    treeNode.dataItem.employeeLoginEmailAddress ===
                      selectedEmployee?.employeeLoginEmailAddress &&
                    treeNode.dataItem.employeeDependentTag ===
                      selectedEmployee?.employeeDependentTag
                  );
                })
            : treeData.filter((treeNode) => {
                return (
                  Array.from(selectedPackages).includes(
                    treeNode.parentId + ""
                  ) && treeNode.dataItem
                );
              })
          )
            .map((node) => node.dataItem)
            .filter((enrollment) => {
              return enrollment.dateOfEnrollment !== null;
            })}
          benefitItems={
            selectedPackage
              ? Array.from([selectedPackage.benefitPackageTag + ""])
              : Array.from(selectedPackages)
          }
        />
      ) : null}
    </>
  );
};

export default BenefitsTree;
