import { DatePicker } from "@fluentui/react-datepicker-compat";
import { useAtom } from "jotai";
import React from "react";
import { historicReportAtom, reportDateAtom } from "store/UIHrPageDashboard";
import { useTranslation } from "react-i18next";

const onFormatDate = (date?: Date | undefined): string => {
  return !date
    ? ""
    : ((date?.getDate() + "").length === 1 ? "0" : "") +
        date?.getDate() +
        "." +
        ((date?.getMonth() + 1 + "").length === 1 ? "0" : "") +
        (date?.getMonth() + 1) +
        "." +
        date?.getFullYear();
};

const FilterDate = () => {
  const [reportDate, setReportDate] = useAtom(reportDateAtom);
  const [historicReport, setHistoricReport] = useAtom(historicReportAtom);
  const { t } = useTranslation();

  const handleCheckboxChange = (e) => {
    const checked = e.target.checked;
    setHistoricReport(checked);
  };

  return (
    <div style={{ display: "flex", alignItems: "center", marginTop: "10px" }}>
      <div style={{ alignItems: "flex-start" }}>
        <label>
          <input
            type="checkbox"
            checked={historicReport}
            onChange={handleCheckboxChange}
            style={{ marginRight: "5px" }}
          />
          {t("hbh.filterDate.historicReport.label")}
        </label>
      </div>
      <div
        style={{
          alignItems: "flex-end",
          marginLeft: "auto",
        }}
      >
        <DatePicker
          style={{ width: "120px" }}
          value={reportDate}
          onSelectDate={(date) => {
            if (date) setReportDate(date);
          }}
          onFocus={(e) => {
            e.preventDefault();
          }}
          openOnClick={false}
          maxDate={new Date()}
          formatDate={onFormatDate}
          placeholder={t("hbh.filterDate.date.placeholder")}
          title={t("hbh.filterDate.date.title")}
          disabled={!historicReport}
        />
      </div>
    </div>
  );
};

export default FilterDate;
