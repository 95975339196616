import { MUTATION_KEYS, QUERY_KEYS } from "library/shared";
import { Attachment, IEmployeeEnrollment } from "library/types";
import { handleAxiosError } from "library/utils";
import { useMutation, useQueryClient } from "react-query";
import api from "service/api";
import { useInvalidateCompanyEmployeeViews } from "./useGetCompanyEmployeeViews";

type Params = {
  countryCode: number | string;
  clientInternalNumberGOS: number | string;
  enrollments: IEmployeeEnrollment[];
};
const deleteEmployeeEnrollments = async ({
  countryCode,
  clientInternalNumberGOS,
  enrollments,
}: Params) => {
  const response = await api.patch(
    `/employeeenrollment/delete/${countryCode}/${clientInternalNumberGOS}`,
    [...enrollments]
  );
  return response.data as Attachment;
};

export function useDeleteEmployeeEnrollments(refetch = true) {
  const queryClient = useQueryClient();
  const invalidateCompanyEmployees = useInvalidateCompanyEmployeeViews();

  return useMutation(deleteEmployeeEnrollments, {
    mutationKey: MUTATION_KEYS.deleteEmployeeEnrollments,
    onSuccess: () => {
      if (refetch) {
        invalidateCompanyEmployees();
        queryClient.invalidateQueries({
          queryKey: QUERY_KEYS.employeeEnrollmentsByEmployee,
        });
        queryClient.invalidateQueries({
          queryKey: QUERY_KEYS.employeeEnrollmentsByPackage,
        });
        queryClient.invalidateQueries({
          queryKey: QUERY_KEYS.companyEmployeeViews,
        });
        queryClient.invalidateQueries({
          queryKey: QUERY_KEYS.companyPackages,
        });
      }
    },
    onError: (error) => {
      // handle error
      handleAxiosError(error);
    },
  });
}
