import { EAppSettings, EBenefitTab } from "library/types";
import { useGetUserAppTargetSetting } from "./useGetUserAppTargetSetting";

export function useSelectedViewTab() {
  const benefitViewModeAppSettings = useGetUserAppTargetSetting({
    targetSettingName: EAppSettings.benefitViewMode,
  });

  return benefitViewModeAppSettings?.userAppSettingValue || EBenefitTab.grid;
}
