import { Text } from "@fluentui/react-components";
import { OnlyActiveSwitch } from "components/OnlyActiveSwitch/OnlyActiveSwitch";
import { useSelectedViewTab } from "hooks/useSelectedViewTab";
import { useAtom } from "jotai";
import {
  EBenefitTab,
  IBenefitCategory,
  IBenefitImplementation,
} from "library/types";
import { useCallback, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useInvalidateBenefitImplementation } from "state/queries/useGetBenefitImplementation";
import { onlyActiveImplementationsState } from "store/UICompanyPage";
import { ImplementedBenefits } from "../ImplementedBenefits/ImplementedBenefits";
import { ImplementationCardList } from "./ImplementationCardList";

type Props = {
  benefitCategory: IBenefitCategory;
  implementedBenefits: IBenefitImplementation[];
};

export function ManageBenefitImplementationsView({
  benefitCategory,
  implementedBenefits,
}: Props) {
  const { t } = useTranslation();

  const [onlyActiveImplementations, setOnlyActiveImplementation] = useAtom(
    onlyActiveImplementationsState
  );

  const onSetOnlyActiveImplementation = useCallback(
    (value) => {
      setOnlyActiveImplementation(value);
    },
    [setOnlyActiveImplementation]
  );

  const selectedTab = useSelectedViewTab();

  const invalidateBenefitImplementations = useInvalidateBenefitImplementation();

  useEffect(() => {
    if (selectedTab === EBenefitTab.grid) {
      invalidateBenefitImplementations();
    }
  }, [selectedTab]);

  return (
    <div>
      <Text
        block
        weight="semibold"
        underline
        size={500}
        style={{
          marginBottom: "20px",
        }}
      >
        {t("hbh.implementedBenefits.label")}
      </Text>
      <OnlyActiveSwitch
        value={onlyActiveImplementations}
        setValue={onSetOnlyActiveImplementation}
      />
      {selectedTab === EBenefitTab.list && (
        <ImplementedBenefits
          benefitCategory={benefitCategory}
          implementedBenefits={implementedBenefits || []}
        />
      )}
      {selectedTab === EBenefitTab.grid && (
        <ImplementationCardList
          withPagination={implementedBenefits?.length > 50}
          benefitCategory={benefitCategory}
          benefitImplementations={implementedBenefits}
        />
      )}
    </div>
  );
}
